import { apiCall } from "service/AuthProvider";

const SERVICE_URL = "dashboard-service/";

export const getDashboardData = async () => {
  const url = `${SERVICE_URL}status`;
  return apiCall("get", url);
};

export const getAuthorDashboardData = async () => {
  const url = `${SERVICE_URL}author`;
  return apiCall("get", url);
};

export const getAudioEngineerDashboardData = async () => {
  const url = `${SERVICE_URL}audioEngineer`;
  return apiCall("get", url);
};
