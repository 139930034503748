import React, { useState, useCallback, useEffect } from "react";
import Cropper from "react-easy-crop";
import {
  Typography,
  Input,
  Button,
  Slider,
  Modal,
  Box,
  Grid,
  Stack,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import getCroppedImg from "../UploadImageModal/cropImage";
import { styles } from "../UploadImageModal/styles";
import { withStyles } from "@material-ui/core/styles";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { smallModalStyle } from "components/common/Styles";
import { uploadProfilePicture } from "service/UserService";
import { UNEXPECTED_ERROR_MESSAGE } from "constants/otherConstant";
import { COLORS } from "constants";

const UploadImageModal = ({
  classes,
  openUploadModal,
  calltoast,
  getProfilePicUrl,
  isUploading,
  fetchProfileData,
  profileData,
}) => {
  const [imageToCrop, setImageToCrop] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [imageSource, setImageSource] = useState("?not-from-cache-please");

  useEffect(() => {
    if (openUploadModal.state) {
      setImageToCrop(
        profileData.state.profileUrl
          ? getProfilePicUrl(profileData.state.profileUrl)
          : ""
      );
    }
  }, [openUploadModal]);

  useEffect(() => {
    isUploading.update(false);
    croppedImage !== null && uploadPicture(croppedImage);
  }, [croppedImage]);

  useEffect(() => {
    setZoom(1);
  }, [imageToCrop]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleZoom = (step) => {
    ((step === -0.1 && zoom > 1) || (step === 0.1 && zoom < 3)) &&
      setZoom(zoom + step);
  };

  const showCroppedImage = useCallback(async () => {
    isUploading.update(true);
    try {
      const croppedImage = await getCroppedImg(
        imageSource,
        imageToCrop,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  async function uploadPicture(fileIs) {
    isUploading.update(true);
    let formData = new FormData();
    formData.append("profilePic", fileIs);
    const response = await uploadProfilePicture(formData);
    if (response.status === 200) {
      openUploadModal.update(false);
      fetchProfileData();
      calltoast("success", "Photo updated successfully");
    } else {
      calltoast("error", UNEXPECTED_ERROR_MESSAGE);
    }
    setCroppedImage(null);
    isUploading.update(false);
  }

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);
      setImageSource("");
      setImageToCrop(imageDataUrl);
    }
  };

  return (
    <Modal
      open={openUploadModal.state}
      onClose={() => openUploadModal.update(false)}
    >
      <Box sx={smallModalStyle}>
        <Grid item xs={12} container justifyContent="flex-end">
          <IconButton
            onClick={(e) => {
              openUploadModal.update(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <div>
          {imageToCrop !== "" && (
            <div className={classes.cropContainer}>
              <Cropper
                image={imageToCrop}
                crop={crop}
                cropShape="round"
                rotation={rotation}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
          )}

          <div className={classes.controls}>
            <Grid container>
              {imageToCrop !== "" && (
                <Grid item xs={12}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <IconButton onClick={() => handleZoom(-0.1)}>
                      <RemoveIcon />
                    </IconButton>
                    <Slider
                      color="secondary"
                      value={zoom}
                      min={1}
                      max={3}
                      step={0.1}
                      aria-labelledby="Zoom"
                      onChange={(e, zoom) => setZoom(zoom)}
                    />
                    <IconButton onClick={() => handleZoom(+0.1)}>
                      <AddIcon />
                    </IconButton>
                  </Stack>
                </Grid>
              )}
              <Grid item xs={12} container justifyContent="center">
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={{ xs: 1, md: 3 }}
                >
                  {!isUploading.state && (
                    <label htmlFor="upload-photo">
                      <Input
                        inputProps={{ accept: "image/*" }}
                        id="upload-photo"
                        type="file"
                        onChange={onFileChange}
                        sx={{ display: "none" }}
                      />
                      <Button
                        disabled={isUploading.state}
                        variant="outlined"
                        component="span"
                        color="secondary"
                        size="small"
                      >
                        <Typography variant="buttonLabel">
                          Choose Photo
                        </Typography>
                      </Button>
                    </label>
                  )}
                  <Button
                    disabled={imageToCrop === "" || isUploading.state}
                    onClick={showCroppedImage}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    <Typography variant="buttonLabel" color={COLORS.white}>
                      Crop & Upload
                    </Typography>
                    {isUploading.state && (
                      <CircularProgress
                        size={24}
                        sx={{
                          position: "absolute",
                          marginLeft: "-10px",
                        }}
                      />
                    )}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default withStyles(styles)(UploadImageModal);
