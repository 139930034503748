import React, { Fragment, useEffect } from "react";
import {
  useIsAdmin,
  useIsAudioEngineer,
  useIsAuthor,
  useIsAuthorOrEngineer,
  useIsSuperAdmin,
} from "utils/GetUserDetail";
import AdminDashboard from "./AdminDashboard/AdminDashboard";
import AuthorDashboard from "./AuthorDashboard/AuthorDashboard";
import AudioEnginnerDashboard from "./AudioEnginnerDashboard/AudioEnginnerDashboard";

function Dashboard({ isHideSideMenu }) {
  const isSuperAdmin = useIsSuperAdmin();
  const isAdmin = useIsAdmin();
  const isAuthor = useIsAuthor();
  const isAudioEngineer = useIsAudioEngineer();
  const isAuthorOrEngineer = useIsAuthorOrEngineer();

  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    isHideSideMenu.update(isAuthorOrEngineer);
  }, [isAuthorOrEngineer]);

  return (
    <Fragment>
      {(isAdmin || isSuperAdmin) && <AdminDashboard />}
      {isAuthor && <AuthorDashboard />}
      {isAudioEngineer && <AudioEnginnerDashboard />}
    </Fragment>
  );
}

export default Dashboard;
