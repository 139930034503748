import React from "react";

import { Button, Typography, Stack, TextField } from "@mui/material";
import { useStateUpdate } from "UseStateUpdate";
import { useNavigate } from "react-router-dom";
import { COLORS } from "constants";

function AdminProjectQuickSearch() {
  let navigate = useNavigate();
  const searchParam = useStateUpdate("");

  const callSearch = () => {
    navigate(`/projects?search=${searchParam.state.trim()}`);
  };

  return (
    <form>
      <Stack spacing={2}>
        <Typography variant="font15b" color={COLORS.primary} noWrap>
          Project search
        </Typography>

        <TextField
          fullWidth
          size="small"
          label="Project name"
          color="primary"
          value={searchParam.state}
          inputProps={{ maxLength: 50 }}
          onChange={(e) => {
            searchParam.update(e.target.value);
          }}
        />
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={() => {
              searchParam.update("");
            }}
          >
            <Typography variant="font12b">Clear</Typography>
          </Button>
          <Button
            disabled={searchParam.state === ""}
            type="submit"
            variant="contained"
            size="small"
            onClick={(e) => {
              e.preventDefault();
              callSearch();
            }}
          >
            <Typography variant="font12b">Search</Typography>
          </Button>
        </Stack>
      </Stack>
    </form>
  );
}

export default AdminProjectQuickSearch;
