import { apiCall } from "service/AuthProvider";
import { apiCallWithFormData } from "./AuthProvider";

const SERVICE_URL = "user-service/";

export const listUsers = async (searchParams) => {
  const url = `${SERVICE_URL}${searchParams}`;
  return apiCall("get", url);
};

export const createUser = async (body) => {
  const url = `${SERVICE_URL}users`;
  return apiCall("post", url, body);
};

export const getUserDetail = async (userId) => {
  const url = `${SERVICE_URL}users/${userId}`;
  return apiCall("get", url);
};

export const getProfileData = async () => {
  const url = `${SERVICE_URL}profile`;
  return apiCall("get", url);
};

export const updateProfile = async (body) => {
  const url = `${SERVICE_URL}profile`;
  return apiCall("put", url, body);
};

export const uploadProfilePicture = async (body) => {
  const url = `${SERVICE_URL}upload/profile-pic`;
  return apiCallWithFormData("put", url, body);
};
