import React from "react";
import {
  TableHead,
  TableRow,
  TableCell,
  Typography,
  Grid,
  CircularProgress,
  TableContainer,
  Box,
  Divider,
  Paper,
  Table,
  TableBody,
  Stack,
} from "@mui/material";
import { rowHoverClickStyle } from "components/common/Styles";
import NoDataFound from "components/common/NoDataFound";
import PaginationRow from "components/common/PaginationRow";
import { COLORS } from "constants";
import { getFormattedDate } from "utils/DateTimeUtils";
import { markNotificationRead } from "service/NotificationsService";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import { useNavigate } from "react-router-dom";

const headCells = [
  { id: "title", label: "Title" },
  { id: "discription", label: "Description" },
  { id: "date", label: "Date" },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id}>
            <Typography variant="font12b" color={COLORS.primary} noWrap>
              {headCell.label}
            </Typography>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
function NotificationTable(props) {
  const {
    page,
    pageCount,
    rowsPerPage,
    isFetching,
    notifications,
    fetchNotifications,
  } = props;
  const classes = rowHoverClickStyle();
  const navigate = useNavigate();

  const callMarkNotificationRead = async (notificationId) => {
    const body = {
      notificationIds: [notificationId],
    };
    const result = await markNotificationRead(body);
    if (result.status === 200) {
      fetchNotifications();
    }
  };

  const renderIcon = (isRead) => {
    return isRead ? <DraftsOutlinedIcon /> : <MarkunreadOutlinedIcon />;
  };

  const renderTypography = (text, isRead, variant) => {
    return (
      <Typography variant={isRead ? "font12" : variant}>{text}</Typography>
    );
  };

  const notificationDataRows = notifications.state?.map((notification) => (
    <TableRow
      key={notification.notificationId}
      hover
      classes={{ hover: classes.hover }}
      onClick={() => {
        navigate(notification.url);
        !notification.isRead &&
          callMarkNotificationRead(notification.notificationId);
      }}
    >
      <TableCell>
        <Stack spacing={1} direction="row" alignItems="center">
          {renderIcon(notification.isRead)}
          {renderTypography(notification.title, notification.isRead, "font13b")}
        </Stack>
      </TableCell>
      <TableCell>
        {renderTypography(
          notification.description,
          notification.isRead,
          "font13b"
        )}
      </TableCell>
      <TableCell>
        {renderTypography(
          getFormattedDate(notification.updatedAt),
          notification.isRead,
          "font13b"
        )}
      </TableCell>
    </TableRow>
  ));

  let tableContent;

  if (isFetching.state) {
    tableContent = (
      <TableRow>
        <TableCell colSpan={3}>
          <Grid container justifyContent="center" alignItems="center">
            <CircularProgress />
          </Grid>
        </TableCell>
      </TableRow>
    );
  } else if (notifications.state.length === 0) {
    tableContent = <NoDataFound colSpanValue="3" />;
  } else {
    tableContent = notificationDataRows;
  }

  return (
    <Grid container item xs={12}>
      <Paper variant="outlined" sx={{ width: "100%" }}>
        <Divider />

        <Box sx={{ width: { xs: "90vw", sm: "100%" } }}>
          <TableContainer component={Paper}>
            <Table size="small">
              <EnhancedTableHead />
              <TableBody>
                {tableContent}
                {notifications.state?.length > 0 && (
                  <PaginationRow
                    perPage={rowsPerPage}
                    page={page}
                    pageCount={pageCount}
                    colSpanValue={3}
                  />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Divider />
      </Paper>
    </Grid>
  );
}

export default NotificationTable;
