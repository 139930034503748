import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import MuiAppBar from "@mui/material/AppBar";
import { COLORS } from "constants";
import { DRAWER_WIDTH } from "constants/otherConstant";
import { basetheme } from "./Theme";
import banner from "assets/images/banner.png";
import Switch from "@mui/material/Switch";

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  padding: theme.spacing(1, 0, 0, 0),
  ...(open && {
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    marginLeft: `${DRAWER_WIDTH}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3, 0),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2.5, 3),
  backgroundColor: COLORS.white,
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

export const rowHoverClickStyle = makeStyles((theme) => ({
  hover: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

export const menuProps = {
  paperProps: {
    elevation: 0,
    sx: {
      overflow: "visible",
      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
      mt: 1.5,
      "& .MuiAvatar-root": {
        width: 32,
        height: 32,
        ml: -0.5,
        mr: 1,
      },
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        right: 14,
        width: 10,
        height: 10,
        bgcolor: "background.paper",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 0,
      },
    },
  },
  transformOrigin: { horizontal: "right", vertical: "top" },
  anchorOrigin: { horizontal: "right", vertical: "bottom" },
};

export const largeModalStyle = {
  position: "absolute",
  top: "50%",
  left: { xs: "50%", md: "55%" },
  overflowY: "auto",
  maxHeight: "85%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", sm: "80%", md: "65%" },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export const smallModalStyle = {
  position: "absolute",
  top: "50%",
  left: { xs: "50%", md: "55%" },
  overflowY: "auto",
  maxHeight: "85%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", sm: "80%", md: "50%" },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export const confirmModalStyle = {
  position: "absolute",
  top: "50%",
  left: { xs: "50%", md: "50%" },
  overflowY: "auto",
  maxHeight: "85%",
  transform: "translate(-50%, -50%)",
  width: { xs: "80%", sm: "80%", md: "35%" },
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 1,
};

export const unRecordedPagesModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  overflowY: "auto",
  transform: "translate(-50%, -50%)",
  width: "90%",
  backgroundColor: "background.paper",
  boxShadow: 24,
  py: 2,
  height: "80%",
};

export const iconButton = {
  border: {
    border: "solid",
    borderRadius: "5px",
    borderWidth: "thin",
    width: "25px",
    height: "25px",
  },
};

export const recordPageFooter = () =>
  makeStyles((theme) => ({
    footer: {
      width: "100%",
      height: "50px",
      backgroundColor: "#888",
      color: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    currentPageField: {
      width: "65px",
      backgroundColor: "#fff",
    },
    infoBtn: { width: "30px", height: "30px" },
  }));

export const contentView = makeStyles((theme) => ({
  tabPanel: {
    backgroundColor: "white",
  },
  playAndEqualizerButton: { width: "30px", height: "30px" },
}));

export const scrollableSide = makeStyles((theme) => ({
  recording: {
    overflow: "auto",
    opacity: 0.75,
    transition: "transform 0.3s ease",
    "&:hover": {
      opacity: 1,
      transform: "scale(1.1)",
    },
  },
  verifying: {
    overflow: "auto",
  },
  grouping: {
    overflow: "auto",
  },
  emailTemplateLeft: {
    maxHeight: "60vh",
    overflow: "auto",
  },
  emailTemplateRight: {
    maxHeight: "70vh",
    overflow: "auto",
  },
  infoBtn: { width: "30px", height: "30px" },
  threads: {
    maxHeight: "80vh",
    overflow: "auto",
  },
  comments: {
    maxHeight: "65vh",
    minHeight: "65vh",
    overflow: "auto",
  },
}));

export const recordingDiv = makeStyles((theme) => ({
  recordButton: {
    backgroundColor: "#fff",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    borderRadius: "50%",
    "&:hover": { boxShadow: "0px 0px 10px 5px rgba(39, 56, 145, 1)" },
  },
  stopRecordButton: {
    backgroundColor: "#fff",
    borderRadius: "50%",
    animation: "$glowing 1.25s infinite",
  },
  "@keyframes glowing": {
    "0%": {
      boxShadow: "0 0 10px #fff",
    },
    "50%": {
      boxShadow: "0 0 40px #00ffea",
    },
    "100%": {
      boxShadow: "0 0 10px #fff",
    },
  },
}));

export const zoomInAndOutIcon = {
  backgroundColor: "#fff",
  borderRadius: "5px",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
};

export const zoomInAndOutIconImage = { width: "20px", height: "20px" };

export const prevAndNextButton = {
  backgroundColor: "#fff",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
  borderRadius: "20px",
  "&:hover": {
    backgroundColor: basetheme.palette.primary.main,
    color: "#fff",
  },
};

export const fabStyles = makeStyles((theme) => ({
  fab: {
    position: "absolute",
    top: theme.spacing(3),
    borderRadius: "0 30px 30px 0",
  },
}));

export const VerifyPageButton = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

export const verifyRightSideButtons = makeStyles((theme) => ({
  button: {
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    border: "1px solid #273891",
    boxShadow: "0 0 5px #273891",
    transition: "transform 0.2s",
    "&:hover": {
      transform: "scale(1.5)",
    },
  },
}));

export const verifyLeftSideButtons = {
  backgroundColor: COLORS.primary50t,
  borderRadius: "25%",
  width: "30px",
  height: "30px",
  transition: "transform 0.2s",
  "&:hover": {
    backgroundColor: COLORS.primary50t,
    transform: "scale(1.5)",
  },
};

export const iconButtonImage = makeStyles((theme) => ({
  buttonImage: { width: "20px", height: "20px" },
}));

export const confirmModalIcon = { width: "50px", height: "50px" };

export const borderLessTextField = makeStyles((theme) => ({
  textField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
    },
  },
}));

export const groupingChaptersStyles = makeStyles((theme) => ({
  cards: {
    background: COLORS.white,
    color: COLORS.primary,
    height: "150px",
    marginTop: "-50px",
    borderRadius: "20px 20px 0 0",
    boxShadow: "1px -10px 10px -3px #d1cdd1",
    cursor: "pointer",
    "&:hover": {
      color: COLORS.white,
      background: COLORS.primary,
    },
  },
}));

export const primaryButtonHover = {
  backgroundColor: COLORS.white,
  "&:hover": {
    backgroundColor: COLORS.primary,
    color: COLORS.white,
  },
};

export const pageNumberIndicator = makeStyles((theme) => {
  const indicatorContainer = {
    backgroundColor: COLORS.primary50t,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const commonStyle = (width, height, marginLeft, marginTop, borderRadius) => ({
    width: `${width}px`,
    height: `${height}px`,
    marginLeft: `${marginLeft}px`,
    marginTop: `${marginTop}px`,
    borderRadius: borderRadius,
    ...indicatorContainer,
  });

  return {
    indicatorContainer,
    verifyPageLeft: commonStyle(35, 35, -35, 10, "50% 0 0 50%"),
    verifyPageRight: commonStyle(30, 30, -38, 0, "20% 0 0 20%"),
    groupingPageLeft: commonStyle(25, 25, -25, 10, "20% 0 0 20%"),
  };
});

export const pageShowingCard = makeStyles((theme) => {
  const commonStyle = (width, height, marginBottom = 0) => ({
    width: `${width}px`,
    height: `${height}px`,
    background: COLORS.white,
    boxShadow: "2px 2px 3px rgba(0,0,0,.3)",
    borderRadius: "10px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    marginBottom: `${marginBottom}px`,
  });

  return {
    recordingPageLeft: commonStyle(150, 200, 50),
    groupingPageRight: commonStyle(120, 180),
  };
});

export const menuHover = makeStyles((theme) => ({
  hover: {
    color: COLORS.primary,
    cursor: "pointer",
    padding: 5,
    position: "relative",

    "&:hover": {
      "&::before": {
        content: '""',
        position: "absolute",
        width: "100%",
        height: 3,
        bottom: 0,
        left: "50%",
        transform: "translateX(-50%) scaleX(0)",
        backgroundColor: COLORS.secondary,
        transition: "transform 0.3s ease",
      },

      "&:hover::before": {
        transform: "translateX(-50%) scaleX(1)",
      },
    },
  },
}));

export const contentViewFooter = {
  width: "100%",
  backgroundColor: COLORS.gray,
  borderRadius: "0 0 10px 10px",
  "&:hover": {
    backgroundColor: COLORS.gray61,
  },
};

export const homePageStyle = {
  banner: {
    backgroundImage: `url(${banner})`,
    backgroundSize: "cover",
    height: "80vh",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right center",
    display: "flex",
    alignItems: "center",
    pl: "12%",
    marginBottom: "100px",
  },
  questionSection: {
    marginBottom: "100px",
    paddingX: "10%",
  },
  platform: {
    backgroundColor: COLORS.secondary,
    textAlign: "center",
    margin: "auto",
    paddingX: { xs: "10px", md: "20%" },
    paddingY: { xs: "100px" },
  },
  knowMoreSection: {
    textAlign: "center",
    marginBottom: "100px",
  },
  footerSection: {
    backgroundColor: "#f5f5f5",
    height: "20vh",
    paddingX: 10,
  },
};

export const optimizePage = {
  pageImage: {
    width: `120px`,
    height: `180px`,
    boxShadow: "0px 0px 4px rgba(0,0,0,.3)",
    borderRadius: "10px",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
};

export const homePage = makeStyles((theme) => ({
  sideMenu: {
    width: "30px",
    height: "30px",
    "&:hover": {
      width: "50px",
      height: "50px",
    },
  },
}));

export const bookRecordedPage = {
  button: { width: "25px", height: "25px" },
};

export const sampleRecording = makeStyles((theme) => ({
  recordButton: {
    backgroundColor: COLORS.white,
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    borderRadius: "50%",
    "&:hover": { boxShadow: "0px 0px 10px 5px rgba(39, 56, 145, 1)" },
  },
}));

export const profileStyle = {
  avatar: { width: 150, height: 150 },
  hover: {
    backgroundColor: "rgba(39, 56, 145, 0.75)",
    borderRadius: "50%",
    position: "absolute",
  },
  avatarBtn: {
    border: "solid",
    borderRadius: "5px",
    borderWidth: "thin",
    width: "30px",
    height: "30px",
  },
};

export const threadsStyles = makeStyles((theme) => ({
  leftSection: {
    backgroundColor: "rgba(209, 209, 209, 0.2)",
    borderRadius: "20px 0px 0px 20px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.5)",
  },
  switchTypeContainer: {
    display: "flex",
    justifyContent: "center",
  },
  switchTypeButton: {
    borderRadius: "20px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: COLORS.primary,
      color: COLORS.white,
    },
  },
  threads: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: COLORS.primary,
      color: COLORS.white,
    },
  },
}));

export const selectRetailSampleModalStyle = {
  scrollableArea: {
    overflowY: "auto",
    maxHeight: "60vh",
    border: "1px solid #e0e0e0",
    borderRadius: 10,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  },
};
