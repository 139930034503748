import React, { Fragment } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  Modal,
  Stack,
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
  TextField,
  Divider,
} from "@mui/material/";
import CloseIcon from "@mui/icons-material/Close";
import { smallModalStyle } from "components/common/Styles";
import { isEmptyString } from "utils/FormValidator";
import { useStateUpdate } from "UseStateUpdate";
import { createProject } from "service/ProjectService";
import { useNavigate } from "react-router-dom";
import Toast from "components/common/Toast";
import { UNEXPECTED_ERROR_MESSAGE } from "../../constants/otherConstant";
import { COLORS } from "constants";

const Footer = ({
  isLoader,
  projectName,
  checkValidatorOnAddProject,
  callCreateProject,
}) => {
  return (
    <Box p={4}>
      <Grid container justifyContent="flex-end">
        <Button
          variant="contained"
          disabled={isLoader.state || !projectName.state}
          size="small"
          onClick={(e) => {
            checkValidatorOnAddProject() && callCreateProject();
          }}
        >
          <Typography variant="font14b" color={COLORS.white} mx={4} noWrap>
            Create project
          </Typography>
          {isLoader.state && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                marginLeft: "-10px",
              }}
            />
          )}
        </Button>
      </Grid>
    </Box>
  );
};

function ProjectCreateModal({ open }) {
  let navigate = useNavigate();
  const projectName = useStateUpdate("");
  const projectDescription = useStateUpdate("");
  const isLoader = useStateUpdate(false);
  const projectNameValidationError = useStateUpdate("");
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);

  function checkValidatorOnAddProject() {
    if (!projectName.state.trim()) {
      projectNameValidationError.update("");
      return false;
    }
    return true;
  }

  const callCreateProject = async () => {
    isLoader.update(true);
    let data = getProjectDetails();
    let response = await createProject(data);
    if (response.status === 200) {
      navigate(`/projects/${response.data.data.projectId}`);
    } else {
      calltoast("error", UNEXPECTED_ERROR_MESSAGE);
    }

    isLoader.update(false);
  };

  const getProjectDetails = () => ({
    projectName: projectName.state.trim(),
    projectDescription: projectDescription.state.trim(),
    languageId: 1,
  });

  const calltoast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  const handleClose = () => {
    projectName.update("");
    projectDescription.update("");
    projectNameValidationError.update("");
    open.update(false);
  };

  return (
    <Fragment>
      <Modal open={open.state} onClose={handleClose}>
        <Box sx={smallModalStyle}>
          <Stack
            direction="row"
            mb={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="font20b" color={COLORS.primary}>
              Create project
            </Typography>
            <Tooltip title="Close">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Stack>
          <Paper variant={"outlined"}>
            <Box p={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    required
                    disabled={isLoader.state}
                    error={
                      projectNameValidationError.state === "" ? false : true
                    }
                    helperText={projectNameValidationError.state}
                    id="project-name"
                    label={
                      <Typography variant="font15">Project name</Typography>
                    }
                    variant="standard"
                    color="primary"
                    fullWidth
                    value={projectName.state}
                    inputProps={{ maxLength: 50 }}
                    onChange={(e) => {
                      projectNameValidationError.update("");
                      projectName.update(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (isEmptyString(e.target.value)) {
                        projectNameValidationError.update(
                          "Project name is required"
                        );
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    color="primary"
                    label={
                      <Typography variant="font15">
                        Enter description
                      </Typography>
                    }
                    multiline
                    rows={2}
                    value={projectDescription.state}
                    onChange={(e) => {
                      projectDescription.update(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Footer
              {...{
                isLoader,
                projectName,
                checkValidatorOnAddProject,
                callCreateProject,
              }}
            />
          </Paper>
        </Box>
      </Modal>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Fragment>
  );
}

export default ProjectCreateModal;
