import React, { useEffect } from "react";
import { Alert, Box, Grid, Typography } from "@mui/material";
import { useStateUpdate } from "UseStateUpdate";
import BookCard from "./sections/BookCard";
import { COLORS } from "constants";
import { getAuthorDashboardData } from "service/DashboardService";
import { useIsAuthorOrEngineer } from "utils/GetUserDetail";
import { ThreeDots } from "react-loader-spinner";

function AuthorDashboard() {
  const books = useStateUpdate(null);
  const isAuthorOrEngineer = useIsAuthorOrEngineer();

  useEffect(() => {
    fetchAuthorDashBoardData();
  }, []);

  const fetchAuthorDashBoardData = async () => {
    const result = await getAuthorDashboardData();
    if (result.status === 200) {
      const { data } = result.data;
      books.update(data[0].Books);
    }
  };

  const statusCodeHeadings = {
    AUDIOOPTIMIZED: "Optimized Audio Books",
    BOOKRECORDING: "Books to Record",
  };

  const renderBooks = () => {
    // Group books by statusCode
    const groupedBooks = books.state.reduce((acc, book) => {
      const statusCode = book.status.statusCode;
      if (!acc[statusCode]) {
        acc[statusCode] = [];
      }
      acc[statusCode].push(book);
      return acc;
    }, {});

    // Render the grouped books
    if (Object.keys(groupedBooks).length === 0) {
      return (
        <Grid item xs={12} container justifyContent="center">
          <Alert severity="info" sx={{ width: { xs: "90vw", sm: "75vw" } }}>
            <Typography variant="font17">
              Your audiobook recordings are currently being optimized by an
              audio engineer. Once completed, a notification email will be sent,
              indicating the process's completion. The email will include a link
              to a page where all chapter-wise recordings can be accessed for
              download.
              <br />
              <br />
              Users will have the option to either "Accept All" or "Reject" the
              recordings using the respective buttons. If any recordings are
              rejected, the opportunity to re-record specific paragraphs for
              enhancement will be provided. Accepted recordings will then be
              moved to the admin for publishing.
            </Typography>
          </Alert>
        </Grid>
      );
    }

    return (
      <>
        {Object.keys(groupedBooks).map((statusCode) => (
          <React.Fragment key={statusCode}>
            <Typography variant="font20b" color={COLORS.primary} sx={{ mb: 2 }}>
              {statusCodeHeadings[statusCode] || statusCode}
            </Typography>
            <Grid container spacing={2} sx={{ mb: 6 }}>
              {groupedBooks[statusCode].map((book) => (
                // <Grid item xs={12} sm={6} md={4} key={book.bookId}>
                <BookCard
                  key={book.bookId}
                  title={book.bookName}
                  description={`Author: ${book.authorName}`}
                  bookStatus={book.status.statusCode}
                  {...{ book }}
                />
                // </Grid>
              ))}
            </Grid>
          </React.Fragment>
        ))}
      </>
    );
  };

  const renderLoading = () => {
    return (
      <Grid item xs={12} container justifyContent="center">
        <ThreeDots height="30" color={COLORS.primary} />
      </Grid>
    );
  };

  return (
    <Box
      mx={{ xs: 2, md: isAuthorOrEngineer ? 10 : 5 }}
      my={isAuthorOrEngineer && 5}
    >
      <Grid mb={2}>
        <Typography variant="font20b" color={COLORS.primary}>
          {/* Books */}
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        {books.state ? renderBooks() : renderLoading()}
      </Grid>
    </Box>
  );
}

export default AuthorDashboard;
