import React from "react";
import {
  Modal,
  Box,
  Grid,
  IconButton,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  confirmModalIcon,
  pageNumberIndicator,
  pageShowingCard,
  primaryButtonHover,
  unRecordedPagesModalStyle,
} from "../common/Styles";
import warningIcon from "assets/images/warningOrange.png";

import { COLORS } from "constants";
import ImageComponent from "components/common/ImageComponent";
import { getImageUrl } from "utils/GetUrl";

function UnRecordedPagesModal(props) {
  const {
    openUnRecordedPagesModal,
    pages,
    selectedIndex,
    pagesNotRecorded,
    callUpdateBookStatus,
  } = props;
  const classes = pageNumberIndicator();
  const classes2 = pageShowingCard();

  const handleClose = () => {
    openUnRecordedPagesModal.update(false);
  };

  return (
    <Modal open={openUnRecordedPagesModal.state}>
      <Box
        sx={{
          ...unRecordedPagesModalStyle,
        }}
      >
        <Stack
          spacing={4}
          alignItems="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Stack spacing={1} alignItems="center" sx={{ width: "100%" }}>
            <Grid
              container
              justifyContent="flex-end"
              px={1}
              sx={{ position: "absolute" }}
            >
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
            <img src={warningIcon} style={confirmModalIcon} alt="icon" />
            <Typography variant="font20" textAlign="center">
              Pages - Yet to be recorded
            </Typography>
            <Typography variant="font16" width={"100%"} textAlign="center">
              The below are the pages that are yet to be recorded .<br /> If you
              are sure its not needed for your audiobook , you can go ahead and
              click “Confirm and Done with Recording” Button.
            </Typography>
          </Stack>
          <Box
            sx={{
              bgcolor: "#eaeff1",
              width: "100%",
              height: "100vh",
              p: 4,
              overflow: "auto",
            }}
          >
            <Grid
              container
              rowSpacing={2}
              columnSpacing={5}
              justifyContent={"center"}
            >
              {pages?.state?.map(
                (page, index) =>
                  pagesNotRecorded.state.includes(page.pageNumber) && (
                    <Grid item key={page.pageId}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        sx={{
                          cursor: "pointer",
                          position: "relative",
                          "&:hover": {
                            border: "2px solid #273891",
                          },
                        }}
                        className={classes2.groupingPageRight}
                        onClick={() => {
                          handleClose();
                          selectedIndex.update(index + 1);
                        }}
                      >
                        <Box className={classes.groupingPageLeft}>
                          <Typography variant="font12b" color={COLORS.white}>
                            {page.pageNumber + 1}
                          </Typography>
                        </Box>
                        <Grid container>
                          <ImageComponent
                            src={getImageUrl(page.pageImageUrl)}
                            style={{
                              width: "100%",
                              height: `100%`,
                              borderRadius: "10px",
                            }}
                            alt="page-image"
                          />
                        </Grid>
                      </Box>
                    </Grid>
                  )
              )}
            </Grid>
          </Box>
          <Stack direction="row" spacing={3}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => {
                callUpdateBookStatus();
              }}
              sx={primaryButtonHover}
            >
              <Typography variant="font15" noWrap>
                Confirm and Done recording
              </Typography>
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={handleClose}
              sx={primaryButtonHover}
            >
              <Typography variant="font15" noWrap>
                Cancel
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
}

export default UnRecordedPagesModal;
