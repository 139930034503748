import React, { useEffect } from "react";
import { Typography, Box, Grid, Stack } from "@mui/material";
import NotificationTable from "./NotificationTable";
import { useLocation } from "react-router-dom";
import { useStateUpdate } from "UseStateUpdate";
import { getNotifications } from "service/NotificationsService";
import { useIsAuthorOrEngineer } from "utils/GetUserDetail";
import { COLORS } from "constants";

function Notifications({ isHideSideMenu }) {
  const search = useLocation().search;
  const searchParam = new URLSearchParams(search).get("search");
  const isFetching = useStateUpdate(true);
  const rowsPerPage = useStateUpdate(10);
  const page = useStateUpdate(1);
  const pageCount = useStateUpdate(0);
  const order = useStateUpdate("desc");
  const notifications = useStateUpdate([]);
  const filterValue = useStateUpdate(searchParam ?? "");
  const isAuthorOrEngineer = useIsAuthorOrEngineer();

  useEffect(() => {
    fetchNotifications();
  }, [rowsPerPage.state, page.state, order.state]);

  useEffect(() => {
    isHideSideMenu.update(isAuthorOrEngineer);
  }, [isAuthorOrEngineer]);

  useEffect(() => {
    page.update(page.state > pageCount.state ? 1 : page.state);
  }, [pageCount.state]);

  const fetchNotifications = async () => {
    let listParams = `?page=${page.state}&limit=${
      rowsPerPage.state
    }&sort=${order.state.toUpperCase()}&filter=${filterValue.state}`;

    let result = await getNotifications(listParams);
    if (result.status === 200) {
      notifications.update(result.data.data.notifications);
      pageCount.update(result.data.data.pagination.totalPages);
    }

    isFetching.update(false);
  };

  return (
    <Box
      ml={{ xs: 2, md: 3 }}
      mr={{ xs: 2, md: 5 }}
      mt={isAuthorOrEngineer && 4}
    >
      <Grid mb={2}>
        <Typography variant="font20b" color={COLORS.primary}>
          Notifications
        </Typography>
      </Grid>
      <Stack spacing={2}>
        <NotificationTable
          {...{
            page,
            pageCount,
            rowsPerPage,
            isFetching,
            notifications,
            fetchNotifications,
          }}
        />
      </Stack>
    </Box>
  );
}

export default Notifications;
