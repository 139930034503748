import {
  setToken,
  getToken,
  isLoggedIn,
  subscribe,
  unsubscribe,
} from "./TokenProvider";
import axios from "components/common/Axios";
import { useState, useEffect } from "react";

export const login = (newTokens) => {
  setToken(newTokens);
};

export const logout = () => {
  localStorage.removeItem("user");
  setToken(null);
};

export const authFetch = async (
  methodName,
  apiUrl,
  body,
  isMultipartFormData
) => {
  const token = await getToken();
  const header = {
    "Content-Type": isMultipartFormData
      ? "multipart/form-data"
      : "application/json",
    Authorization: `Bearer ${token}`,
  };
  let config = {
    method: methodName,
    url: apiUrl,
    headers: header,
    data: body,
  };
  return await axios(config);
};

export const apiCall = async (method, url, body = null) => {
  try {
    const response = await authFetch(method, url, body);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const apiCallWithFormData = async (method, url, body) => {
  try {
    const response = await authFetch(method, url, body, true);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const useAuth = () => {
  const [isLogged, setIsLogged] = useState(isLoggedIn());

  useEffect(() => {
    const listener = (newIsLogged) => {
      setIsLogged(newIsLogged);
    };

    subscribe(listener);
    return () => {
      unsubscribe(listener);
    };
  }, []);

  return isLogged;
};
