import React from "react";
import {
  Modal,
  Box,
  Grid,
  IconButton,
  Stack,
  Typography,
  Button,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  confirmModalIcon,
  confirmModalStyle,
  primaryButtonHover,
} from "../common/Styles";
import { BOOK_STATUS_CODES as BookStatusCodes } from "constants";
import { sendComment } from "service/ChatService";
import { getUserId } from "utils/GetUserDetail";
import { THREAD_TYPES } from "constants";
import OptimizeIcon from "assets/images/optimizeIcon.png";
import { useStateUpdate } from "UseStateUpdate";

function RejectMessageModal(props) {
  const { openRejectMessageModal, callUpdateBookStatus, bookName, bookId } =
    props;
  const reasonMessage = useStateUpdate("");
  const handleClose = () => {
    openRejectMessageModal.update(false);
  };

  const callCreateThread = async () => {
    let data = getThreadDetails();
    let response = await sendComment(data);
    if (response.status === 200) {
      callUpdateBookStatus(BookStatusCodes.bookRecording);
    }
  };

  const getThreadDetails = () => {
    return {
      title: `Optimization rejected: [${bookId}]${bookName}`,
      description: "",
      message: reasonMessage.state.trim(),
      receiverUserId: getUserId(),
      threadType: THREAD_TYPES.other,
      pageUri: ``,
    };
  };

  return (
    <Modal open={openRejectMessageModal.state}>
      <Box
        sx={{
          ...confirmModalStyle,
          width: { xs: "80%", sm: "80%", md: "50%" },
        }}
      >
        <Grid container justifyContent="flex-end" px={1}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Stack spacing={4} alignItems="center">
          <Stack spacing={2} alignItems="center">
            <img src={OptimizeIcon} style={confirmModalIcon} alt="icon" />
            <Typography variant="font20" textAlign="center">
              Reject optimized audio
            </Typography>{" "}
            <Typography variant="font15" width={"90%"} textAlign="center">
              After rejecting the optimized audio, you'll be able to to
              re-record specific paragraphs to further enhance the quality
            </Typography>
          </Stack>
          <Box bgcolor="#eaeff1" width="100%" p={4}>
            <Stack spacing={4} alignItems="center">
              <TextField
                multiline
                rows={4}
                placeholder="Please provide your reason for rejection."
                style={{
                  width: "100%",
                  backgroundColor: "white",
                }}
                value={reasonMessage.state}
                onChange={(event) => {
                  reasonMessage.update(event.target.value);
                }}
              />

              <Stack direction="row" spacing={3}>
                <Button
                  disabled={!reasonMessage.state}
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    callCreateThread();
                  }}
                  sx={primaryButtonHover}
                >
                  <Typography variant="font15" noWrap>
                    Send message and Reject
                  </Typography>
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleClose}
                  sx={primaryButtonHover}
                >
                  <Typography variant="font15" noWrap>
                    Cancel
                  </Typography>
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
}

export default RejectMessageModal;
