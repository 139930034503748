import React, { Fragment, useContext, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";

import { scrollableSide, primaryButtonHover } from "components/common/Styles";
import GroupingLeftScrollable from "./sections/GroupingLeftScrollable";
import GroupingRightScrollable from "./sections/GroupingRightScrollable";
import Info from "assets/images/info.png";
import GroupingGuideLine from "./sections/GroupingGuideLine";

import { PageViewModal, ConfirmModal, WarningModal } from "components/modals";
import resetIcon from "assets/images/reset.png";
import { checkBookStatus } from "utils/Checkbookstatus";

import AccessDenied from "components/common/AccessDenied";
import { useIsAuthorOrEngineer } from "utils/GetUserDetail";
import { MOBILE_MAX_WIDTH } from "constants/otherConstant";
import { GroupingPageContext } from "context/context";
import { COLORS } from "constants";

function BookGrouping({ isToolbarHidden }) {
  const {
    pagesToDisplay,
    openWarningModal,
    warningModalMessage,
    navigateTo,
    fetchBookDetails,
    bookDetails,
    BookStatusCodes,
    fetchGroupedChapters,
    fetchedPages,
    openGuideLineModal,
    openConfirmModal,
    openPageViewModal,
    confirmModalData,
    pageUrl,
    isFetching,
    isShowingGroupedPages,
    chapters,
    selectedFirstPage,
    setConfirmModalData,
    callUpdateBookStatus,
    callResetChapters,
    callAddNewChapter,
  } = useContext(GroupingPageContext);
  const classes = scrollableSide();
  const isAuthorOrEngineer = useIsAuthorOrEngineer();

  const isMobile = window.innerWidth <= MOBILE_MAX_WIDTH;

  useEffect(() => {
    if (isMobile) {
      openWarningModal.update(true);
      warningModalMessage.update(
        "You cannot use this page on this device. Please use a desktop instead."
      );
      navigateTo.update("/dashboard");
    } else {
      !isAuthorOrEngineer && fetchBookDetails();
    }
  }, []);

  useEffect(() => {
    if (bookDetails.state) {
      const bookStatus = checkBookStatus(
        BookStatusCodes.bookVerified,
        bookDetails.state,
        bookDetails.state.pages.length,
        "Currently, this book is not in the grouping stage."
      );

      openWarningModal.update(bookStatus.isOpenModal);
      warningModalMessage.update(bookStatus.warningMessage);
      navigateTo.update(bookStatus.navigatePath);

      if (!bookStatus.isOpenModal) {
        fetchGroupedChapters();
      }
    }
  }, [bookDetails.state]);

  useEffect(() => {
    pagesToDisplay.update(fetchedPages.state);
  }, [fetchedPages.state]);

  return isAuthorOrEngineer ? (
    <AccessDenied />
  ) : (
    <Box pt={5} px={5}>
      <PageViewModal open={openPageViewModal} pageUrl={pageUrl.state} />
      <ConfirmModal {...{ openConfirmModal, confirmModalData }} />
      <WarningModal
        {...{ openWarningModal, warningModalMessage, navigateTo }}
      />
      <GroupingGuideLine {...{ openGuideLineModal }} />

      <Grid container rowSpacing={2} columnSpacing={7}>
        <Grid container item xs={12}>
          <Grid item xs={2.5} pb={2}>
            <Typography variant="font32b" color={COLORS.primary}>
              Chapters
            </Typography>
          </Grid>
          <Grid item xs={3.5} container alignItems="flex-end" pb={2}>
            <Typography variant="font20" color={COLORS.primary}>
              Ungrouped pages
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            container
            justifyContent="flex-end"
            alignItems="flex-end"
            pb={2}
          >
            {!isFetching.state && (
              <Stack direction="row" spacing={2}>
                {isShowingGroupedPages.state ? (
                  <Button
                    variant="outlined"
                    sx={primaryButtonHover}
                    onClick={() => {
                      pagesToDisplay.update(fetchedPages.state);
                      isShowingGroupedPages.update(false);
                    }}
                  >
                    <Typography variant="font15b">Close</Typography>
                  </Button>
                ) : (
                  <Fragment>
                    {fetchedPages.state.length === 0 ? (
                      <Button
                        variant="outlined"
                        sx={primaryButtonHover}
                        onClick={callUpdateBookStatus}
                      >
                        <Typography variant="font15b">Complete</Typography>
                      </Button>
                    ) : (
                      <Tooltip title="Please select the pages by clicking on them to be grouped into their respective chapters.">
                        <Box>
                          <Button
                            disabled={selectedFirstPage.state < 0}
                            variant="outlined"
                            sx={primaryButtonHover}
                            onClick={callAddNewChapter}
                          >
                            <Typography variant="font15b">
                              + Add chapter
                            </Typography>
                          </Button>
                        </Box>
                      </Tooltip>
                    )}
                    <Button
                      disabled={chapters.state.length <= 2}
                      variant="outlined"
                      sx={primaryButtonHover}
                      onClick={(e) => {
                        e.stopPropagation();
                        setConfirmModalData(
                          resetIcon,
                          "Reset Chapters",
                          "Are you sure you want to reset all Chapters?",
                          "Yes, reset all chapters",
                          "Cancel, keep chapters",
                          () => {
                            callResetChapters();
                          },
                          null
                        );
                      }}
                    >
                      <Typography variant="font15b">Reset all</Typography>
                    </Button>
                  </Fragment>
                )}
                <Tooltip title="Guideline info">
                  <IconButton
                    size="small"
                    sx={{ boxShadow: "0px 0px 10px 0px #d1cdd1" }}
                    onClick={(e) => {
                      openGuideLineModal.update(true);
                    }}
                  >
                    <img src={Info} className={classes.infoBtn} alt="Info" />
                  </IconButton>
                </Tooltip>
              </Stack>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={2.5}
          className={classes.grouping}
          sx={{ maxHeight: isToolbarHidden.state ? "90vh" : "70vh" }}
          p={1}
          pb={5}
          pr={2}
        >
          <GroupingLeftScrollable />
        </Grid>
        <Grid
          item
          xs={9.5}
          className={classes.grouping}
          sx={{ maxHeight: isToolbarHidden.state ? "90vh" : "70vh" }}
          pb={5}
        >
          {!isFetching.state && <GroupingRightScrollable />}
        </Grid>
      </Grid>
    </Box>
  );
}

export default BookGrouping;
