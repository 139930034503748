import {
  Stack,
  Box,
  Typography,
  Button,
  TextField,
  useMediaQuery,
  FormControl,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { useStateUpdate } from "UseStateUpdate";
import { UNEXPECTED_ERROR_MESSAGE } from "constants/otherConstant";
import { COLORS } from "constants";
import {
  isValidEmail,
  isValidPhoneNumber,
  isEmptyString,
} from "utils/FormValidator";
import {
  formatPhoneNumber,
  reFormatPhoneNumber,
} from "utils/FormatPhoneNumber";
import { homePageStyle } from "components/common/Styles";
import Toast from "components/common/Toast";
import { contactUs } from "service/SupportService";

function QuestionSection() {
  const isXs = useMediaQuery((theme) => theme.breakpoints.only("xs"));
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);

  const name = useStateUpdate("");
  const title = useStateUpdate("");
  const email = useStateUpdate("");
  const phoneNumber = useStateUpdate("");
  const message = useStateUpdate("");
  const isLoader = useStateUpdate(false);

  const validationError = useStateUpdate({
    name: "",
    phoneNumber: "",
    email: "",
  });

  const resetFeilds = async () => {
    name.update("");
    email.update("");
    phoneNumber.update("");
    title.update("");
    message.update("");
  };

  const callContactUs = async () => {
    isLoader.update(true);
    let data = getContactDetails();
    let response = await contactUs(data);
    if (response.status === 200) {
      calltoast(
        "success",
        `Your contact details have been sent. An admin will reach out to you.`
      );
      resetFeilds();
    } else {
      calltoast("error", UNEXPECTED_ERROR_MESSAGE);
    }
    isLoader.update(false);
  };

  const getContactDetails = () => ({
    userName: name.state,
    email: email.state,
    phone: reFormatPhoneNumber(phoneNumber.state),
    title: title.state,
    message: message.state,
  });

  const calltoast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  function checkValidatorOnSubmit() {
    const { state: nameValue } = name;
    const { state: emailValue } = email;
    const { state: phoneNumberValue } = phoneNumber;

    const newValidationError = {
      email: "",
      name: "",
      phoneNumber: "",
    };

    if (isEmptyString(nameValue)) {
      newValidationError.name = "Name is required";
    }
    if (isEmptyString(emailValue)) {
      newValidationError.email = "Email is required";
    } else if (!isValidEmail(emailValue)) {
      newValidationError.email = "Email is not valid.";
    }
    if (!isValidPhoneNumber(phoneNumberValue)) {
      newValidationError.phoneNumber = "Phone Number is not valid";
    }

    validationError.update(newValidationError);
    return Object.values(newValidationError).every((value) => value === "");
  }

  return (
    <Box sx={homePageStyle.questionSection}>
      <Stack spacing={1}>
        <Typography
          variant={isXs ? "font25b" : "font35b"}
          align="center"
          color={COLORS.grey}
        >
          Got questions?
        </Typography>
        <Typography
          variant={isXs ? "font25b" : "font35b"}
          align="center"
          color={COLORS.grey}
        >
          We're one email away:
        </Typography>

        <Box bgcolor="#dff2fa" width="100%" px="10%" py={8}>
          <Stack spacing={2}>
            <FormControl>
              <TextField
                required
                disabled={isLoader.state}
                error={Boolean(validationError.state.name)}
                id="first-name"
                label={<Typography variant="font15">Your name</Typography>}
                size="small"
                fullWidth
                value={name.state}
                inputProps={{ maxLength: 50 }}
                sx={{
                  backgroundColor: COLORS.white,
                }}
                onChange={(e) => {
                  validationError.update({
                    ...validationError.state,
                    name: "",
                  });
                  name.update(e.target.value);
                }}
                onBlur={(e) => {
                  if (isEmptyString(e.target.value)) {
                    validationError.update({
                      ...validationError.state,
                      name: "Name is required",
                    });
                  }
                }}
              />{" "}
              <FormHelperText error={Boolean(validationError.state.name)}>
                {validationError.state.name}
              </FormHelperText>
            </FormControl>
            <TextField
              fullWidth
              disabled={isLoader.state}
              size="small"
              label={<Typography variant="font15">Book title</Typography>}
              value={title.state}
              onChange={(e) => {
                title.update(e.target.value);
              }}
              sx={{
                backgroundColor: COLORS.white,
              }}
            />
            <FormControl>
              <TextField
                required
                disabled={isLoader.state}
                error={Boolean(validationError.state.email)}
                type="email"
                id="email"
                label={<Typography variant="font15">Email</Typography>}
                size="small"
                fullWidth
                value={email.state}
                inputProps={{ maxLength: 50 }}
                sx={{
                  backgroundColor: COLORS.white,
                }}
                onChange={(e) => {
                  validationError.update({
                    ...validationError.state,
                    email: "",
                  });
                  email.update(e.target.value);
                }}
                onBlur={(e) => {
                  if (isEmptyString(e.target.value)) {
                    validationError.update({
                      ...validationError.state,
                      email: "Email is required",
                    });
                  } else if (!isValidEmail(e.target.value)) {
                    validationError.update({
                      ...validationError.state,
                      email: "Email is not valid.",
                    });
                  }
                }}
              />{" "}
              <FormHelperText error={Boolean(validationError.state.email)}>
                {validationError.state.email === ""
                  ? ""
                  : validationError.state.email}
              </FormHelperText>
            </FormControl>
            <FormControl>
              <TextField
                required
                disabled={isLoader.state}
                error={Boolean(validationError.state.phoneNumber)}
                id="phone-number"
                label={<Typography variant="font15">Phone number</Typography>}
                size="small"
                fullWidth
                value={phoneNumber.state}
                inputProps={{ maxLength: 16 }}
                sx={{
                  backgroundColor: COLORS.white,
                }}
                onChange={(e) => {
                  validationError.update({
                    ...validationError.state,
                    phoneNumber: "",
                  });
                  phoneNumber.update(formatPhoneNumber(e.target.value));
                }}
                onBlur={(e) => {
                  !isValidPhoneNumber(e.target.value) &&
                    validationError.update({
                      ...validationError.state,
                      phoneNumber: "Phone Number is not valid",
                    });
                }}
              />
              <FormHelperText
                error={Boolean(validationError.state.phoneNumber)}
              >
                {validationError.state.phoneNumber}
              </FormHelperText>
            </FormControl>
            <TextField
              fullWidth
              disabled={isLoader.state}
              size="small"
              label={<Typography variant="font15">Your message</Typography>}
              multiline
              rows={4}
              value={message.state}
              onChange={(e) => {
                message.update(e.target.value);
              }}
              sx={{
                backgroundColor: COLORS.white,
              }}
            />
            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                disabled={isLoader.state}
                onClick={(e) => {
                  checkValidatorOnSubmit() && callContactUs();
                }}
              >
                <Typography variant="font12b">Contact Us</Typography>
                {isLoader.state && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      marginLeft: "-10px",
                    }}
                  />
                )}
              </Button>
            </Box>
          </Stack>
        </Box>
      </Stack>{" "}
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Box>
  );
}

export default QuestionSection;
