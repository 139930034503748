import { Stack, Box, Typography, Container } from "@mui/material";
import { COLORS } from "constants";
import recordingPageScreen from "assets/images/recordingPageScreen.png";
import loginPageScreen from "assets/images/login-page.png";
import scrubbing from "assets/images/scrubbing.png";
import scrubIcon from "assets/images/equalizer.png";
import videoSample from "assets/videos/myaudiobookrecorder.mp4";

function RecordYourBook() {
  return (
    <Box>
      <Container maxWidth="md">
        <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font40b"} color={COLORS.primary}>
              Step 2: Record your book
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              To ensure that your setup is complete, we have come up with a
              sample recording page. The main objective of this page is to get
              the background noise of your recording room if any and ensure the
              quality of the recording.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              If the quality of the recording is showing red status, you will
              not be able to record for the audiobook.
            </Typography>
          </Stack>
        </Box>
        <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font35b"} color={COLORS.primary}>
              Steps to record your book
            </Typography>
          </Stack>
        </Box>
        <Box width="100%" display="flex" justifyContent="center">
          <video
            controls
            width="90%"
            style={{
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.25)",
            }}
          >
            <source src={videoSample} type="video/mp4" />
          </video>
        </Box>
        <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font30b"} color={COLORS.primary}>
              Login/Register
            </Typography>
            <Box width="100%" sx={{ textAlign: "center" }}>
              <img
                src={loginPageScreen}
                width="90%"
                alt="recordingPageScreen"
              />
            </Box>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              The first step is to go to our My Audio Book Recorder website.
              Click the login button from the top menu. You will be directed to
              the login page. Once the admin creatd the account you will get a
              email notification with a link. by clickin on that link it will
              redirect to My Audio Book Recorder . for the first time you can
              create you first password. After you have successfully created the
              password, you can login by using your email and new password.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              If the quality of the recording is showing red status, you will
              not be able to record for the audiobook.
            </Typography>
          </Stack>
        </Box>
        <div id="recordAudio"></div>
        <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font30b"} color={COLORS.primary}>
              Record your audio
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              Once you assigned to a book, you can go to recording page. On this
              page, you can record the voice for different instances of your
              audiobook. The left corner contains all the pages that were
              uploaded and can be previewed at any time. Clicking on any page
              tile populates the right corner of the webpage with its contents.
              These paragraphs can be recorded as they are selected and the text
              associated with them will be displayed within the textbox in the
              middle.
            </Typography>
            <Box width="100%" sx={{ textAlign: "center" }}>
              <img
                src={recordingPageScreen}
                width="90%"
                alt="recordingPageScreen"
              />
            </Box>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              Click on the mic button to start recording and the stop button can
              be pressed once you are done with that audio section. A noise
              profile tile is added as it is required to evaluate the
              surrounding environment. A silence sample is recorded as it is a
              must for an audio engineer to work with it. A green checkmark is
              appended to each tile to indicate that the audio is successfully
              recorded for that page. A play and scrub button becomes available
              at the bottom of the text box as soon the recording is done for
              every file. The play/pause button is used for general playback.
              The scrub button, on the other hand, helps you to audit every
              audio file recorded. Features like adding silence sessions in
              between the audio, clipping and saving audio queues are offered.
              Click the next button to move on to the next content and finish
              recording your audios. And once you finish recording all your
              paragraphs, click on the ‘done with recording button on the
              bottom. The button can be clicked only when all the paragraphs are
              recorded.
            </Typography>
          </Stack>
        </Box>
        <div id={"scrubAudio"}></div>
        <Box py={5}>
          <Stack spacing={3}>
            <Typography variant={"font30b"}>
              Scrubbing your recorded audio.
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              {
                "After completing a paragraph recording, you can scrub the recorded audio by clicking on this "
              }
              <img src={scrubIcon} width="25px" alt="mic" />
              {" icon."}
            </Typography>
            <Box display="flex" justifyContent="center">
              <img
                src={scrubbing}
                width="90%"
                style={{
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                }}
                alt="scrubbing"
              />
            </Box>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              The scrubbing tool will be shown in a modal, where the current
              paragraph text and the recorded audio in wave format are
              displayed. Within this modal, can select a region in the wave to
              trim by clicking the clip button. The selected area will be
              removed. Additionally, it have the option to undo the clip by
              clicking the "Undo clip" button and save the scrubbed audio. This
              tool is useful for removing unwanted portions from recorded audio
              clips.
            </Typography>{" "}
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              For significant audio modifications, it is recommended to
              re-record the specific paragraph.
            </Typography>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}

export default RecordYourBook;
