import React, { useEffect } from "react";
import {
  Box,
  Grid,
  Modal,
  IconButton,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Table,
  Paper,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { smallModalStyle } from "components/common/Styles";
import { useStateUpdate } from "UseStateUpdate";

const headCells = [
  { id: "number", label: "#" },
  { id: "quality_factor", label: "Quality factor	" },
  { id: "current_value", label: "Current value" },
  { id: "recommended", label: "Recommended" },
];

function createRow(id, qualityFactor, currentValue, recommended, isCorrect) {
  return { id, qualityFactor, currentValue, recommended, isCorrect };
}

function QualityReportModal({
  showQualityReportModal,
  sampleSampleRate,
  sampleBitRate,
  sampleChannelCount,
  sampleDecibel,
}) {
  const tableRows = useStateUpdate([]);

  const handleClose = () => {
    showQualityReportModal.update(false);
  };

  useEffect(() => {
    const rows = [
      createRow(
        1,
        "Sampling rate (Hz)",
        sampleSampleRate.state,
        "44100",
        sampleSampleRate.state >= 44100
      ),
      createRow(
        2,
        "Bit rate (Kbps)	",
        sampleBitRate.state,
        "320",
        sampleBitRate.state >= 320
      ),
      createRow(
        3,
        "Decibel (dB)	",
        sampleDecibel.state,
        "-23 to -18",
        sampleDecibel.state > -23 && sampleDecibel.state < -18
      ),
      createRow(
        4,
        "Channel",
        sampleChannelCount.state,
        "mono",
        sampleChannelCount.state === "mono"
      ),
    ];
    tableRows.update(rows);
  }, [
    sampleSampleRate.state,
    sampleBitRate.state,
    sampleChannelCount.state,
    sampleDecibel.state,
  ]);

  return (
    <Modal open={showQualityReportModal.state} onClose={handleClose}>
      <Box sx={smallModalStyle}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="font25b">Quality report</Typography>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Grid item xs={12} container justifyContent="center" mt={3}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow height="50px">
                  {headCells.map((headCell) => (
                    <TableCell
                      key={headCell.id}
                      style={{ border: `1px solid #ccc` }}
                    >
                      <Typography variant="font15b" noWrap>
                        {headCell.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableRows.state.map((row, index) => (
                  <TableRow
                    key={row.id}
                    height="50px"
                    style={{ backgroundColor: row.id % 2 === 1 && "#e6e3e3" }}
                  >
                    <TableCell style={{ border: `1px solid #ccc` }}>
                      <Typography variant="font15">{row.id}</Typography>
                    </TableCell>
                    <TableCell style={{ border: `1px solid #ccc` }}>
                      <Typography variant="font15">
                        {row.qualityFactor}
                      </Typography>
                    </TableCell>
                    <TableCell
                      style={{
                        border: `1px solid #ccc`,
                        backgroundColor:
                          row.isCorrect && "rgba(89, 203, 183, 1)",
                      }}
                    >
                      <Typography variant="font15">
                        {row.currentValue}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ border: `1px solid #ccc` }}>
                      <Typography variant="font15">
                        {row.recommended}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>
    </Modal>
  );
}

export default QualityReportModal;
