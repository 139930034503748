import React from "react";
import { Button, Typography } from "@mui/material";
import { primaryButtonHover } from "components/common/Styles";
import { BOOK_STATUS_CODES as BookStatusCodes } from "constants";
import OptimizeIcon from "assets/images/optimizeIcon.png";
import { useIsAuthor } from "utils/GetUserDetail";

function RejectButton({
  status,
  isWavePlaying,
  pauseAudio,
  setConfirmModalData,
  callUpdateBookStatus,
  openRejectMessageModal,
}) {
  const isAuthor = useIsAuthor();

  const handleRejectButton = () => {
    isWavePlaying.state && pauseAudio();
    const isBookRecordedStage = status === BookStatusCodes.bookRecorded;
    const modalTitle = isBookRecordedStage
      ? "Reject audio"
      : "Reject optimized audio";
    const modalConfirmationMessage = isBookRecordedStage
      ? "Are you sure you want to reject the audio? It will go to the recording process."
      : "Are you sure you want to reject the audio? It will undergo the optimization process.";
    // const updateStatusCode = isBookRecorded
    //   ? BookStatusCodes.bookRecording
    //   : BookStatusCodes.bookRecorded;
    const updateStatusCode = BookStatusCodes.bookRecording;

    if (!isBookRecordedStage && isAuthor) {
      openRejectMessageModal.update(true);
    } else {
      setConfirmModalData(
        OptimizeIcon,
        modalTitle,
        modalConfirmationMessage,
        "Yes, Reject",
        "Cancel",
        () => {
          callUpdateBookStatus(updateStatusCode);
        },
        null
      );
    }
  };

  return (
    !(
      status === BookStatusCodes.readyForProduction ||
      status === BookStatusCodes.completed
    ) && (
      <Button
        variant="outlined"
        sx={primaryButtonHover}
        onClick={handleRejectButton}
      >
        <Typography variant="font15b">Reject All</Typography>
      </Button>
    )
  );
}

export default RejectButton;
