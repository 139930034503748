import React from "react";
import { Button, Typography } from "@mui/material";
import { primaryButtonHover } from "components/common/Styles";
import OptimizeIcon from "assets/images/optimizeIcon.png";
import { BOOK_STATUS_CODES as BookStatusCodes } from "constants";

function ConfirmButton({
  status,
  isConfirmBtnDisable,
  isWavePlaying,
  pauseAudio,
  handleDownload,
  bookDetails,
  setConfirmModalData,
  callUpdateBookStatus,
}) {
  const handleConfirmButton = () => {
    isWavePlaying.state && pauseAudio();
    let modalTitle, modalConfirmationMessage, updateStatusCode;

    switch (status) {
      case BookStatusCodes.optimizationInProgress:
        modalTitle = "Complete Optimization";
        modalConfirmationMessage =
          "Are you sure you want to complete the audio optimization?";
        updateStatusCode = BookStatusCodes.audioOptimized;
        break;
      case BookStatusCodes.audioOptimized:
        modalTitle = "Ready for production";
        modalConfirmationMessage =
          "Are you sure you want to mark this as ready for production?";
        updateStatusCode = BookStatusCodes.readyForProduction;
        break;
      case BookStatusCodes.readyForProduction:
        modalTitle = "Book completed";
        modalConfirmationMessage =
          "Are you sure you want to mark this as completed?";
        updateStatusCode = BookStatusCodes.completed;
        break;
      case BookStatusCodes.completed:
        handleDownload(bookDetails.state.compressedFileUrl);
        break;
      default:
        return;
    }

    if (status !== BookStatusCodes.completed) {
      setConfirmModalData(
        OptimizeIcon,
        modalTitle,
        modalConfirmationMessage,
        "Yes",
        "Cancel",
        () => {
          callUpdateBookStatus(updateStatusCode);
        },
        null
      );
    }
  };

  const getButtonLabel = () => {
    switch (status) {
      case BookStatusCodes.optimizationInProgress:
        return "Complete Optimization";
      case BookStatusCodes.audioOptimized:
        return "Accept All";
      case BookStatusCodes.completed:
        return "Download zip file";
      case BookStatusCodes.readyForProduction:
        return "Complete book";
      default:
        return "";
    }
  };

  return (
    <Button
      disabled={isConfirmBtnDisable.state}
      variant="outlined"
      sx={primaryButtonHover}
      onClick={handleConfirmButton}
    >
      <Typography variant="font15b" noWrap>
        {getButtonLabel()}
      </Typography>
    </Button>
  );
}

export default ConfirmButton;
