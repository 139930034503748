import React from "react";
import { Typography, Box, Grid, Stack } from "@mui/material";
import { THREAD_TYPES as threadTypes, COLORS } from "constants";
import { scrollableSide, threadsStyles } from "components/common/Styles";
import CircleIcon from "@mui/icons-material/Circle";
import { useIsAuthorOrEngineer } from "utils/GetUserDetail";
import { capitalize } from "utils/WordsUtils";

function ThreadItem({
  classes2,
  thread,
  selectedThreadId,
  threadTitleForDisplay,
  threadPageUri,
  threadUserForDisplay,
  callMarkThreadAsRead,
}) {
  const isThreadSelected = thread.threadId === selectedThreadId.state;
  const isAuthorOrEngineer = useIsAuthorOrEngineer();

  const handleThreadClick = () => {
    selectedThreadId.update(thread.threadId);
    threadTitleForDisplay.update(`${thread.title} #${thread.threadId}`);
    threadPageUri.update(thread.pageUri);
    !isAuthorOrEngineer &&
      threadUserForDisplay.update(`${capitalize(thread.fullName)}`);
    !thread.isRead && callMarkThreadAsRead(thread.threadId);
  };

  return (
    <Box
      key={thread.threadId}
      className={classes2.threads}
      p={2}
      sx={{
        color: isThreadSelected ? COLORS.white : COLORS.primary,
        backgroundColor: isThreadSelected && COLORS.primary,
        boxShadow: isThreadSelected && "0px 4px 4px rgba(0, 0, 0, 0.5)",
      }}
      onClick={handleThreadClick}
    >
      <Stack>
        <Stack
          direction="row"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Typography variant={thread.isRead ? "font16" : "font16b"}>
            {thread.title}
          </Typography>
          {!thread.isRead && (
            <CircleIcon
              style={{
                fontSize: 15,
              }}
            />
          )}
        </Stack>
        <Typography variant="font15">{thread.description}</Typography>
      </Stack>
    </Box>
  );
}

function RenderSwitchButton({ type, threadType, classes2 }) {
  return (
    <Box
      className={classes2.switchTypeButton}
      sx={{
        backgroundColor: threadType.state === type ? COLORS.primary : "grey",
        color: threadType.state === type ? COLORS.white : COLORS.gray,
      }}
      mr={1}
      px={2}
      onClick={() => {
        threadType.update(type);
      }}
    >
      <Typography variant="font13b">
        {type === threadTypes.general ? "General" : "Others"}
      </Typography>
    </Box>
  );
}

function ThreadsListArea({
  threads,
  selectedThreadId,
  threadTitleForDisplay,
  threadPageUri,
  threadUserForDisplay,
  callMarkThreadAsRead,
  threadType,
  isAuthorOrEngineer,
}) {
  const classes = scrollableSide();
  const classes2 = threadsStyles();

  return (
    <Grid
      item
      xs={2.5}
      className={`${classes.threads} ${classes2.leftSection}`}
    >
      <Stack>
        {!isAuthorOrEngineer && (
          <Box className={classes2.switchTypeContainer} p={1}>
            {[threadTypes.general, threadTypes.other].map((type) => (
              <RenderSwitchButton
                key={type}
                type={type}
                {...{ threadType, classes2 }}
              />
            ))}
          </Box>
        )}
        {threads.state?.map((thread) => (
          <ThreadItem
            key={thread.threadId}
            {...{
              classes2,
              thread,
              selectedThreadId,
              threadTitleForDisplay,
              threadPageUri,
              threadUserForDisplay,
              callMarkThreadAsRead,
            }}
          />
        ))}
      </Stack>
    </Grid>
  );
}

export default ThreadsListArea;
