import React from "react";
import { Typography, Box, Grid, Stack } from "@mui/material";
import AudioEngineerTable from "./sections/AudioEngineerTable";
import { useLocation } from "react-router-dom";
import { useStateUpdate } from "UseStateUpdate";
import { UserCreateModal } from "components/modals";
import { COLORS } from "constants";
import AccessDenied from "components/common/AccessDenied";
import { listUsers } from "service/UserService";
import { useIsAuthorOrEngineer } from "utils/GetUserDetail";

function ManageAudioEngineers() {
  const search = useLocation().search;
  const isAuthorOrEngineer = useIsAuthorOrEngineer();
  const searchParam = new URLSearchParams(search).get("search");
  const openModal = useStateUpdate(false);
  const isFetching = useStateUpdate(true);
  const rowsPerPage = useStateUpdate(10);
  const page = useStateUpdate(1);
  const pageCount = useStateUpdate(0);
  const order = useStateUpdate("asc");
  const orderBy = useStateUpdate("firstName");
  const audioEngineersList = useStateUpdate(null);
  const filterValue = useStateUpdate(searchParam ?? "");

  const callFetchAudioEngineers = () => {
    fetchAudioEngineers();
  };

  const fetchAudioEngineers = async () => {
    let listParams = `audio-engineers?page=${page.state}&limit=${
      rowsPerPage.state
    }&sortBy=${orderBy.state}&sort=${order.state.toUpperCase()}&filter=${
      filterValue.state
    }`;

    let result = await listUsers(listParams);
    if (result.status === 200) {
      audioEngineersList.update(result.data.data.users);
      pageCount.update(result.data.data.pagination.totalPages);
    }
    isFetching.update(false);
  };

  return isAuthorOrEngineer ? (
    <AccessDenied />
  ) : (
    <Box ml={{ xs: 2, md: 3 }} mr={{ xs: 2, md: 5 }}>
      <UserCreateModal
        open={openModal}
        userTypeIs={"audio engineer"}
        fetchUsers={fetchAudioEngineers}
      />
      <Grid mb={2}>
        <Typography variant="font20b" color={COLORS.primary}>
          Audio engineers
        </Typography>
      </Grid>
      <Stack spacing={2}>
        <AudioEngineerTable
          {...{
            openModal,
            page,
            pageCount,
            filterValue,
            rowsPerPage,
            orderBy,
            order,
            isFetching,
            audioEngineersList,
            callFetchAudioEngineers,
          }}
        />
      </Stack>
    </Box>
  );
}

export default ManageAudioEngineers;
