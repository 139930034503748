import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "service/AuthProvider";

function ProtectedRoute({ children }) {
  let location = useLocation();

  const isAuthenticated = useAuth();

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
}

export default ProtectedRoute;
