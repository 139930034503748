import React, { Fragment, useEffect } from "react";
import {
  Box,
  Grid,
  IconButton,
  Modal,
  Button,
  Typography,
  Paper,
  CircularProgress,
  Divider,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Chip,
  Checkbox,
  ListItemText,
} from "@mui/material/";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { smallModalStyle } from "components/common/Styles";
import { useStateUpdate } from "UseStateUpdate";
import Toast from "components/common/Toast";
import { COLORS } from "constants";
import { UNEXPECTED_ERROR_MESSAGE } from "../../constants/otherConstant";
import { listUsers } from "service/UserService";
import { getBookDetailsWithAuthor } from "service/BookService";
import { capitalize } from "../../utils/WordsUtils";
import { assignAuthors } from "service/BookService";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(userId, selectedAuthors, theme) {
  return {
    fontWeight:
      selectedAuthors.findIndex((user) => user.userId === userId) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const Footer = ({
  isLoader,
  callAssignAuthor,
  isFetchingAuthors,
  selectedAuthors,
  arraysAreEqual,
  previouslyAssignedAuthors,
}) => {
  return (
    <Box p={4}>
      <Grid container justifyContent="flex-end">
        <Button
          variant="contained"
          disabled={
            selectedAuthors.state.length === 0 ||
            isFetchingAuthors.state ||
            arraysAreEqual(
              previouslyAssignedAuthors.state,
              selectedAuthors.state
            )
          }
          size="small"
          onClick={(e) => {
            callAssignAuthor();
          }}
        >
          <Typography variant="font14b" color={COLORS.white} mx={4} noWrap>
            Assign authors
          </Typography>
          {isLoader.state && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                marginLeft: "-10px",
              }}
            />
          )}
        </Button>
      </Grid>
    </Box>
  );
};

function AssignAuthorModal(props) {
  const { openAssignAuthorModal, currentBook, callUpdateBookStatus } = props;
  const isLoader = useStateUpdate(false);
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);
  const authorsList = useStateUpdate(null);
  const isFetchingAuthors = useStateUpdate(true);
  const bookDetails = useStateUpdate(null);
  const previouslyAssignedAuthors = useStateUpdate([]);
  const selectedAuthors = useStateUpdate([]);
  const dropDownAuthorsName = useStateUpdate([]);

  const theme = useTheme();

  useEffect(() => {
    currentBook.state && fetchAuthors();
  }, [currentBook.state]);

  useEffect(() => {
    if (authorsList.state) {
      const tempList = authorsList.state.map(
        ({ firstName, lastName, email, userId }) => ({
          name: `${capitalize(firstName)} ${capitalize(lastName)} (${email})`,
          userId,
        })
      );
      dropDownAuthorsName.update(tempList);
    }
  }, [authorsList.state]);

  useEffect(() => {
    dropDownAuthorsName.state.length > 0 && fetchBookDetailsWithAuthor();
  }, [dropDownAuthorsName.state]);

  useEffect(() => {
    const { Users } = bookDetails.state || {};

    if (Users) {
      const authors = dropDownAuthorsName.state.filter((author) =>
        Users.some((user) => author.userId === user.userId)
      );
      previouslyAssignedAuthors.update(authors);
      selectedAuthors.update(authors);
    }
  }, [bookDetails.state, dropDownAuthorsName.state]);

  const fetchBookDetailsWithAuthor = async () => {
    let result = await getBookDetailsWithAuthor(currentBook.state.bookId);
    if (result.status === 200) {
      const data = result.data.data;
      bookDetails.update(data);
    }
  };

  const getAuthorsIds = (authors) => {
    return authors.map((author) => author.userId);
  };

  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }

    return (
      arr1.every((item) => arr2.includes(item)) &&
      arr2.every((item) => arr1.includes(item))
    );
  }

  const callAssignAuthor = async () => {
    isLoader.update(true);

    const newAssignedAuthors = selectedAuthors.state.filter(
      (item) => !previouslyAssignedAuthors.state.includes(item)
    );

    if (newAssignedAuthors.length) {
      const dataToAssign = {
        userIds: getAuthorsIds(newAssignedAuthors),
      };

      let response = await assignAuthors(
        currentBook.state.bookId,
        dataToAssign,
        1
      );
      if (response.status === 200) {
        calltoast("success", "Authors has been assigned");
        callUpdateBookStatus();
        setTimeout(handleClose, 2000);
      } else {
        calltoast("error", UNEXPECTED_ERROR_MESSAGE);
      }
    }

    const unAssignedAuthors = previouslyAssignedAuthors.state.filter(
      (item) => !selectedAuthors.state.includes(item)
    );

    if (unAssignedAuthors.length) {
      const dataToUnAssign = {
        userIds: getAuthorsIds(unAssignedAuthors),
      };

      let response = await assignAuthors(
        currentBook.state.bookId,
        dataToUnAssign,
        0
      );
      if (response.status === 200) {
        setTimeout(handleClose, 1000);
      }
    }

    isLoader.update(false);
  };

  const calltoast = async (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  const fetchAuthors = async () => {
    let result = await listUsers("authors");
    if (result.status === 200) {
      authorsList.update(result.data.data.users);
      isFetchingAuthors.update(false);
    }
  };

  const handleClose = () => {
    currentBook.update(null);
    isLoader.update(false);
    dropDownAuthorsName.update([]);
    previouslyAssignedAuthors.update([]);
    selectedAuthors.update([]);
    bookDetails.update(null);
    openAssignAuthorModal.update(false);
  };

  const handleMultipleChange = (event) => {
    const {
      target: { value },
    } = event;
    selectedAuthors.update(value);
  };

  const isAuthorSelected = (userId) => {
    return selectedAuthors.state.some((author) => author.userId === userId);
  };

  return (
    <Fragment>
      <Modal open={openAssignAuthorModal.state} onClose={handleClose}>
        <Box sx={smallModalStyle}>
          <Stack
            direction="row"
            mb={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="font20b" color={COLORS.primary}>
              Assign authors
            </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Paper variant={"outlined"}>
            <Box p={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="font15">{`Book Name: `}</Typography>
                  {currentBook.state && (
                    <Typography variant="font18b" color={COLORS.primary}>
                      {currentBook.state.bookName}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {!isFetchingAuthors.state && (
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="multiple-author-assign-label">
                        Authors
                      </InputLabel>
                      <Select
                        labelId="multiple-author-assign-label"
                        id="multiple-author-assign"
                        multiple
                        value={selectedAuthors.state}
                        onChange={handleMultipleChange}
                        input={
                          <OutlinedInput
                            id="select-multiple-author-assign"
                            label="Authors"
                          />
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected.map((author) => (
                              <Chip key={author.userId} label={author.name} />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {dropDownAuthorsName.state.map((author) => (
                          <MenuItem
                            key={author.userId}
                            value={author}
                            style={getStyles(
                              author.userId,
                              selectedAuthors.state,
                              theme
                            )}
                          >
                            <Checkbox
                              checked={isAuthorSelected(author.userId)}
                            />
                            <ListItemText primary={author.name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Footer
              {...{
                isLoader,
                callAssignAuthor,
                isFetchingAuthors,
                selectedAuthors,
                arraysAreEqual,
                previouslyAssignedAuthors,
              }}
            />
          </Paper>
        </Box>
      </Modal>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Fragment>
  );
}

export default AssignAuthorModal;
