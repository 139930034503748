import React, { useEffect } from "react";
import { Alert, Box, Grid, Typography } from "@mui/material";
import { useStateUpdate } from "UseStateUpdate";
import { UNEXPECTED_ERROR_MESSAGE } from "constants/otherConstant";
import { COLORS, BOOK_STATUS_CODES as BookStatusCodes } from "constants";
import { getAudioEngineerDashboardData } from "service/DashboardService";
import BookCard from "../AuthorDashboard/sections/BookCard";
import { assignUser } from "service/BookService";
import { getUserId, useIsAuthorOrEngineer } from "utils/GetUserDetail";
import Toast from "components/common/Toast";
import { ThreeDots } from "react-loader-spinner";
import { assignAuthors, updateBookStatus } from "service/BookService";

function AudioEnginnerDashboard() {
  const isAuthorOrEngineer = useIsAuthorOrEngineer();
  const booksInList = useStateUpdate(null);
  const booksNotAssigned = useStateUpdate(null);
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);

  useEffect(() => {
    fetchAudioEngineerDashBoardData();
  }, []);

  const fetchAudioEngineerDashBoardData = async () => {
    const result = await getAudioEngineerDashboardData();
    if (result.status === 200) {
      const { data } = result.data;
      booksInList.update(data.BooksAssignedToAudioEngineer);
      booksNotAssigned.update(data.booksWithoutAudioEngineer);
    }
  };

  const callAssignAudioEngineer = async (bookId) => {
    try {
      let data = {
        userIds: [getUserId()],
      };
      let response = await assignAuthors(bookId, data, 1);

      if (response.status === 200) {
        const body = { statusCode: BookStatusCodes.optimizationInProgress };
        const result = await updateBookStatus(bookId, body);

        if (result.status === 200) {
          calltoast("success", "Book added to list");
          fetchAudioEngineerDashBoardData();
        } else {
          calltoast("error", "Failed to update book status.");
        }
      } else {
        calltoast("error", UNEXPECTED_ERROR_MESSAGE);
      }
    } catch (error) {
      calltoast("error", `An error occurred: ${error.message}`);
    }
  };

  const calltoast = async (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  const renderBooksInList = () => {
    return booksInList.state.length === 0 ? (
      <Grid item xs={12} container justifyContent="center">
        <Alert severity="info">
          No books are in your list! Please check the available books below.
        </Alert>
      </Grid>
    ) : (
      booksInList.state.map((book) => (
        <BookCard
          key={book.bookId}
          title={book.bookName}
          description={`Author: ${book.authorName}`}
          bookStatus={book.status.statusCode}
          {...{ book }}
        />
      ))
    );
  };

  const renderBooksNotAssigned = () => {
    return booksNotAssigned.state.length === 0 ? (
      <Grid item xs={12} container justifyContent="center">
        <Alert severity="info">
          Currently, no books are available now. Please check later.
        </Alert>
      </Grid>
    ) : (
      booksNotAssigned.state.map((book) => (
        <BookCard
          isAddToAudioEngineerList
          callAssignAudioEngineer={() => {
            callAssignAudioEngineer(book.bookId);
          }}
          key={book.bookId}
          title={book.bookName}
          description={`Author: ${book.authorName}`}
          bookStatus={book.status.statusCode}
          {...{ book }}
        />
      ))
    );
  };

  const renderLoading = () => {
    return <ThreeDots height="30" color={COLORS.primary} />;
  };

  return (
    <Box
      mx={{ xs: 2, md: isAuthorOrEngineer ? 10 : 5 }}
      my={isAuthorOrEngineer && 5}
    >
      <Grid mb={2}>
        <Typography variant="font20b" color={COLORS.primary}>
          Books in list
        </Typography>
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent={!booksInList.state && "center"}
        mb={4}
      >
        {booksInList.state ? renderBooksInList() : renderLoading()}
      </Grid>

      <Grid mb={4}>
        <Typography variant="font20b" color={COLORS.primary}>
          Books were not assigned.
        </Typography>
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent={!booksNotAssigned.state && "center"}
        mb={4}
      >
        {booksNotAssigned.state ? renderBooksNotAssigned() : renderLoading()}
      </Grid>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Box>
  );
}

export default AudioEnginnerDashboard;
