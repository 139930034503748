import { AES, enc } from "crypto-js";

export const encryptData = (type, data) => {
  const encryptedData = AES.encrypt(
    JSON.stringify(data),
    process.env.REACT_APP_ENCRYPT_SECRET_KEY
  ).toString();
  localStorage.setItem(type, encryptedData);
};

export const decryptData = (type) => {
  const encryptedData = localStorage.getItem(type);
  if (encryptedData === null) return null;
  try {
    const decryptedData = AES.decrypt(
      encryptedData,
      process.env.REACT_APP_ENCRYPT_SECRET_KEY
    ).toString(enc.Utf8);
    return JSON.parse(decryptedData);
  } catch (error) {
    console.error("Error decrypting data:", error);
    return null;
  }
};
