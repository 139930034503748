import { encryptData, decryptData } from "utils/EncryptDecryptLocal";
import { login } from "./AuthProvider";
import axios from "components/common/Axios";
const SERVICE_URL = "/auth-service/";

export const loginUser = async (userData) => {
  let url = `${SERVICE_URL}login`;
  return await axios
    .post(url, userData)
    .then(function (response) {
      if (response.status === 200) {
        let userData = response.data.data.user;
        encryptData("user", userData);
        let session = {
          refreshToken: response.data.data.tokens.refreshToken,
          accessToken: response.data.data.tokens.accessToken,
        };
        login(session);
      }
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const updateToken = async (refreshToken) => {
  const token = decryptData("token");

  const header = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token.accessToken}`,
  };
  let url = `${SERVICE_URL}token`;
  let data = {
    refreshToken: refreshToken,
  };
  return await axios({
    method: "post",
    url: url,
    data: data,
    headers: header,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};

export const resetPassword = async (token, password) => {
  let url = `${SERVICE_URL}password-reset/tokens/${token}/update`;
  let data = {
    newPassword: password,
  };
  return await axios
    .put(url, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return {};
    });
};

export const verifyEmailToken = async (token) => {
  let url = `${SERVICE_URL}password-reset/tokens/${token}/verify`;

  return await axios
    .get(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return {};
    });
};

export const sendRecoveryMail = async (email) => {
  let url = `${SERVICE_URL}password/recovery`;
  let data = {
    email: email,
  };
  return await axios
    .post(url, data)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return {};
    });
};

export const changePassword = async (data) => {
  const token = decryptData("token");
  const header = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token.accessToken}`,
  };
  let url = `${SERVICE_URL}password/update`;
  return await axios({
    method: "put",
    url: url,
    data: data,
    headers: header,
  })
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      return error.response;
    });
};
