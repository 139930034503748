import React from "react";
import { Modal, Box, Stack, Typography, Button } from "@mui/material";
import { smallModalStyle, primaryButtonHover } from "../common/Styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";

function WarningModal(props) {
  const { openWarningModal, warningModalMessage, navigateTo } = props;
  const navigate = useNavigate();

  const handleOk = () => {
    navigate(navigateTo.state);
  };

  return (
    <Modal open={openWarningModal.state}>
      <Box sx={{ ...smallModalStyle, p: 0 }}>
        <Stack spacing={4} alignItems="center" mt={3}>
          <ErrorOutlineIcon sx={{ fontSize: "75px", color: "error.main" }} />
          <Box bgcolor="#eaeff1" width={"100%"} p={4}>
            <Stack spacing={2} alignItems="center">
              <Typography variant="font17" textAlign="center">
                {warningModalMessage.state}
              </Typography>
              <Button
                variant="outlined"
                size="small"
                sx={primaryButtonHover}
                onClick={handleOk}
              >
                <Typography variant="font15" noWrap>
                  OK
                </Typography>
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
}

export default WarningModal;
