import React from "react";

import BooksRecorded from "components/BooksRecorded/BooksRecorded";
import { BOOK_STATUS_CODES as BookStatusCodes } from "constants";

function OptimizedAudio() {
  return <BooksRecorded status={BookStatusCodes.audioOptimized} />;
}

export default OptimizedAudio;
