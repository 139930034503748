import React from "react";
import {
  Grid,
  TableCell,
  TableRow,
  TableHead,
  Typography,
  Box,
  Paper,
  Divider,
  TableContainer,
  Table,
  TableBody,
} from "@mui/material";
import NoDataFound from "components/common/NoDataFound";
import { COLORS } from "constants";

const headCells = [
  { id: "bookName", label: "Book name" },
  { id: "authorName", label: "Author name" },
  { id: "publisherName", label: "Publisher name" },
];

function UserBookTable(props) {
  const { booksList } = props;

  const bookDataRows = booksList.state.map((row, index) => (
    <TableRow key={row.bookId}>
      <TableCell>
        <Typography variant="font12">{row.bookName}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="font12">{row.authorName}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="font12">{row.publisherName}</Typography>
      </TableCell>
    </TableRow>
  ));

  let tableContent;

  if (booksList.state.length === 0) {
    tableContent = <NoDataFound colSpanValue={5} />;
  } else {
    tableContent = bookDataRows;
  }

  return (
    <Grid container item xs={12}>
      <Paper variant="outlined" sx={{ width: "100%" }}>
        <Box sx={{ width: { xs: "90vw", sm: "100%" } }}>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {headCells.map((headCell) => (
                    <TableCell key={headCell.id}>
                      <Typography
                        variant="font15b"
                        color={COLORS.primary}
                        noWrap
                      >
                        {headCell.label}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>{tableContent}</TableBody>
            </Table>
          </TableContainer>
        </Box>

        <Divider />
      </Paper>
    </Grid>
  );
}

export default UserBookTable;
