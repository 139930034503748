import React from "react";
import {
  Typography,
  Stack,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

function ShowItemsPerPage({ perPage, items }) {
  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="flex-end"
      alignItems="flex-end"
    >
      <Typography variant="font15b">Show:</Typography>
      <FormControl color="primary" variant="standard" size="small">
        <Select
          value={perPage.state}
          onChange={(e) => perPage.update(e.target.value)}
        >
          {items.map((item) => (
            <MenuItem value={item} key={item}>
              {`${item} items`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}

export default ShowItemsPerPage;
