import React from "react";
import Chart from "react-google-charts";
import { Box } from "@mui/material";

function DrawChart({ chartType, data, width, options }) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Chart
        chartType={chartType}
        data={data}
        width={width}
        options={options}
      />
    </Box>
  );
}
export default DrawChart;
