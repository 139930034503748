import React, { useContext } from "react";
import {
  Box,
  Grid,
  IconButton,
  Typography,
  Stack,
  Button,
  Tooltip,
} from "@mui/material";
import Info from "assets/images/info.png";
import { recordPageFooter, primaryButtonHover } from "components/common/Styles";
import FooterPageNumber from "components/common/FooterPageNumber";
import warningIcon from "assets/images/warning.png";
import { RecordingPageContext } from "context/context";

function RecordPageFooter(props) {
  const { isDisabled } = props;
  const {
    selectedIndex,
    totalPages,
    openGuideLineModal,
    isRecordingCompleted,
    callUpdateBookStatus,
    setConfirmModalData,
    openUnRecordedPagesModal,
    pagesNotRecorded,
  } = useContext(RecordingPageContext);
  const useStyles = recordPageFooter();
  const classes = useStyles();

  return (
    <Box className={classes.footer} sx={{ position: "fixed", bottom: 0 }}>
      <Grid container p={1}>
        <Grid item xs={6}>
          <FooterPageNumber {...{ selectedIndex, totalPages, isDisabled }} />
        </Grid>
        <Grid item xs={6}>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              disabled={!isRecordingCompleted.state}
              sx={primaryButtonHover}
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => {
                if (pagesNotRecorded.state.length) {
                  openUnRecordedPagesModal.update(true);
                } else {
                  setConfirmModalData(
                    warningIcon,
                    "Done recording?",
                    // "Are you sure you want to done recording....?",
                    <Typography variant="font17" textAlign="center">
                      Once you click <b>Yes, done recording</b>, your recording
                      is final. No edits can be made.
                      <br /> <br /> Ready to proceed?
                    </Typography>,
                    "Yes, done recording",
                    "Cancel",
                    () => {
                      callUpdateBookStatus();
                    },
                    null
                  );
                }
              }}
            >
              <Typography variant="font15b">Done with recording</Typography>
            </Button>
            <Tooltip title="Guideline info">
              <IconButton
                size="small"
                onClick={(e) => {
                  openGuideLineModal.update(true);
                }}
              >
                <img src={Info} className={classes.infoBtn} alt="Info" />
              </IconButton>
            </Tooltip>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default RecordPageFooter;
