import React, { useEffect } from "react";
import { Typography, Stack, TextField, Button } from "@mui/material";
import { primaryButtonHover, recordPageFooter } from "./Styles";
import { useStateUpdate } from "UseStateUpdate";

function FooterPageNumber(props) {
  const { selectedIndex, totalPages, isDisabled } = props;
  const useStyles = recordPageFooter();
  const classes = useStyles();
  const currentPageNumber = useStateUpdate(selectedIndex.state);

  useEffect(() => {
    currentPageNumber.update(selectedIndex.state);
  }, [selectedIndex.state]);

  const handleGoToPage = (e) => {
    e.preventDefault();
    if (currentPageNumber.state > 0) {
      if (currentPageNumber.state <= totalPages.state) {
        selectedIndex.update(currentPageNumber.state);
      } else {
        selectedIndex.update(parseInt(totalPages.state));
      }
    } else {
      selectedIndex.update(1);
    }
  };

  return (
    <form>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="font15b">Page no</Typography>
        <TextField
          className={classes.currentPageField}
          type="number"
          size="small"
          variant="outlined"
          value={currentPageNumber.state}
          onInput={(e) => {
            const enteredValue = parseInt(e.target.value);
            if (
              !isNaN(enteredValue) &&
              enteredValue >= 1 &&
              enteredValue <= parseInt(totalPages.state)
            ) {
              currentPageNumber.update(enteredValue);
            } else {
              currentPageNumber.update("");
            }
          }}
        />
        <Typography variant="font15b">/</Typography>
        <Typography variant="font15b">{totalPages.state}</Typography>
        <Button
          disabled={isDisabled}
          sx={primaryButtonHover}
          variant="outlined"
          size="small"
          type="submit"
          onClick={handleGoToPage}
        >
          <Typography variant="font15b">Go</Typography>
        </Button>
      </Stack>
    </form>
  );
}

export default FooterPageNumber;
