import React from "react";
import { Box, Grid, Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { smallModalStyle } from "components/common/Styles";

function PageViewModal({ open, pageUrl }) {
  const handleClose = () => {
    open.update(false);
  };

  return (
    <Modal open={open.state} onClose={handleClose}>
      <Box sx={smallModalStyle}>
        <Grid item xs={12} container justifyContent="flex-end">
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Box>
          <img src={pageUrl} style={{ width: "100%" }} alt="" />
        </Box>
      </Box>
    </Modal>
  );
}

export default PageViewModal;
