import React, { useEffect } from "react";
import { useStateUpdate } from "UseStateUpdate";
import { loginUser } from "service/AuthService";
import { useNavigate, useLocation } from "react-router-dom";

import {
  Button,
  Container,
  Hidden,
  Link,
  Stack,
  TextField,
  Typography,
  Box,
  Grid,
  IconButton,
  Input,
  InputLabel,
  FormControl,
  FormHelperText,
  Tooltip,
  InputAdornment,
} from "@mui/material";

import logInPoster from "assets/images/logInPoster.png";
import { isEmptyString } from "utils/FormValidator";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { COLORS } from "constants";
import { useAuth } from "service/AuthProvider";

function SignIn() {
  const isAuthenticated = useAuth();
  const errorMsg = useStateUpdate("");
  const email = useStateUpdate("");
  const password = useStateUpdate("");
  const showPassword = useStateUpdate(false);
  let navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    isAuthenticated && navigate("/dashboard");
  }, []);

  const validationError = useStateUpdate({
    email: "",
    password: "",
  });

  function checkValidatorOnSubmit() {
    const newValidationError = {
      email: isEmptyString(email.state) ? "Email is required*" : "",
      password: isEmptyString(password.state) ? "Password is required*" : "",
    };

    validationError.update(newValidationError);
    return Object.values(newValidationError).every((error) => !error);
  }

  function getUserData() {
    let userData = {
      email: email.state.trim(),
      password: password.state.trim(),
    };
    return userData;
  }

  async function callLogin(e) {
    e.preventDefault();
    let userData = getUserData();
    let response = await loginUser(userData);
    if (response.status === 200) {
      const { from } = location.state || { from: { pathname: "/dashboard" } };
      navigate(from.pathname);
    } else {
      errorMsg.update("Username or password is incorrect.");
    }
  }

  return (
    <Container maxWidth="md">
      <Box mt={{ xs: 10, sm: 6, md: 5 }}>
        <Grid container sx={{ backgroundColor: COLORS.white }}>
          <Hidden only={["sm", "xs"]}>
            <Grid
              container
              item
              md={6}
              p={2}
              justifyContent="flex-start"
              alignItems="stretch"
            >
              <img src={logInPoster} alt="log-in-poster" loading="lazy" />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={10} md={6}>
            <form>
              <Stack spacing={2} sx={{ px: 5 }}>
                <Typography
                  variant="font20b"
                  align="left"
                  sx={{ mb: 5, mt: 7 }}
                >
                  Log in to your account
                </Typography>

                <TextField
                  id="login-email"
                  label="Email"
                  variant="standard"
                  color="primary"
                  value={email.state}
                  inputProps={{ maxLength: 50 }}
                  error={validationError.state.email ? true : false}
                  helperText={
                    <Typography variant="font14" color="error">
                      {validationError.state.email}
                    </Typography>
                  }
                  onChange={(e) => {
                    validationError.update({
                      ...validationError.state,
                      email: "",
                    });
                    errorMsg.update("");
                    email.update(e.target.value);
                  }}
                />

                <FormControl variant="standard" color="primary">
                  <InputLabel
                    error={validationError.state.password ? true : false}
                  >
                    Password
                  </InputLabel>
                  <Input
                    id="login-password"
                    error={validationError.state.password === "" ? false : true}
                    type={showPassword.state ? "text" : "password"}
                    value={password.state}
                    onChange={(e) => {
                      validationError.update({
                        ...validationError.state,
                        password: "",
                      });
                      errorMsg.update("");
                      password.update(e.target.value);
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            showPassword.state
                              ? "Hide password"
                              : "Show password"
                          }
                        >
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              showPassword.update(!showPassword.state)
                            }
                            onMouseDown={(e) => e.preventDefault()}
                          >
                            {showPassword.state ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText
                    error={validationError.state.password === "" ? false : true}
                    id="standard-weight-helper-text"
                  >
                    <Typography variant="font14" color="error">
                      {validationError.state.password}
                    </Typography>
                  </FormHelperText>
                </FormControl>

                <Stack>
                  <Typography variant="font12" color="error">
                    {errorMsg.state}
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  alignItems="center"
                  sx={{ pt: errorMsg.state === "" ? 5 : 3, pb: 2 }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={(e) => {
                      checkValidatorOnSubmit() && callLogin(e);
                    }}
                  >
                    <Typography variant="containedbuttonLabel">
                      Log in
                    </Typography>
                  </Button>
                </Stack>

                <Link
                  href="/forgot-password"
                  color={COLORS.black}
                  variant="font12b"
                  underline="none"
                >
                  {"Forgot password?"}
                </Link>
              </Stack>
            </form>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default SignIn;
