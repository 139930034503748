export const DASHBOARD_MENU = [
  {
    label: "Dashboard",
    path: "/dashboard",
    userTypes: ["SUPERADMIN", "ADMIN", "AUTHOR"],
  },
  { label: "Admins", path: "/admins", userTypes: ["SUPERADMIN"] },
  { label: "Authors", path: "/authors", userTypes: ["SUPERADMIN", "ADMIN"] },
  { label: "Projects", path: "/projects", userTypes: ["SUPERADMIN", "ADMIN"] },
  {
    label: "Audio engineers",
    path: "/audio-engineers",
    userTypes: ["SUPERADMIN", "ADMIN"],
  },
  {
    label: "Email templates",
    path: "/email-templates",
    userTypes: ["SUPERADMIN"],
  },
];
