import { Stack, Box, Typography, useMediaQuery } from "@mui/material";
import { COLORS } from "constants";
import { homePageStyle } from "components/common/Styles";

function LandingSection() {
  const isXs = useMediaQuery((theme) => theme.breakpoints.only("xs"));

  return (
    <Box sx={homePageStyle.banner}>
      <Stack>
        <Typography
          variant={isXs ? "font35b" : "font90b"}
          textAlign={{ xs: "center", md: "left" }}
          color={COLORS.white}
        >
          Build Your Own
        </Typography>
        <Typography
          variant={isXs ? "font35b" : "font90b"}
          textAlign={{ xs: "center", md: "left" }}
          color={COLORS.white}
        >
          Audiobook
        </Typography>
      </Stack>
    </Box>
  );
}

export default LandingSection;
