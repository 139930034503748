import React from "react";

import BooksRecorded from "components/BooksRecorded/BooksRecorded";
import { BOOK_STATUS_CODES as BookStatusCodes } from "constants";

function ReadyForProduction() {
  return <BooksRecorded status={BookStatusCodes.readyForProduction} />;
}

export default ReadyForProduction;
