import React from "react";
import {
  Modal,
  Box,
  Grid,
  IconButton,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  confirmModalIcon,
  confirmModalStyle,
  primaryButtonHover,
} from "../common/Styles";

function ConfirmModal(props) {
  const { openConfirmModal, confirmModalData } = props;

  const handleClose = () => {
    openConfirmModal.update(false);
  };

  const onCancelClick = confirmModalData.state.onCancel
    ? confirmModalData.state.onCancel
    : handleClose;

  return (
    <Modal open={openConfirmModal.state}>
      <Box sx={confirmModalStyle}>
        <Grid container justifyContent="flex-end" px={1}>
          <IconButton onClick={onCancelClick}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Stack spacing={4} alignItems="center">
          <Stack spacing={2} alignItems="center">
            <img
              src={confirmModalData.state.icon}
              style={confirmModalIcon}
              alt="icon"
            />
            <Typography variant="font20" textAlign="center">
              {confirmModalData.state.title}
            </Typography>
          </Stack>
          <Box bgcolor="#eaeff1" width="100%" p={4}>
            <Stack spacing={4} alignItems="center">
              <Typography variant="font17" textAlign="center">
                {confirmModalData.state.message}
              </Typography>
              <Stack direction="row" spacing={3}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={confirmModalData.state.onConfirm}
                  sx={primaryButtonHover}
                >
                  <Typography variant="font15" noWrap>
                    {confirmModalData.state.confirmButton}
                  </Typography>
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={onCancelClick}
                  sx={primaryButtonHover}
                >
                  <Typography variant="font15" noWrap>
                    {confirmModalData.state.cancelButton}
                  </Typography>
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
}

export default ConfirmModal;
