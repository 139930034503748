import React, { Fragment } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  CircularProgress,
  Modal,
  Stack,
  IconButton,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { smallModalStyle } from "components/common/Styles";
import { isEmptyString } from "utils/FormValidator";
import { useStateUpdate } from "UseStateUpdate";
import Toast from "components/common/Toast";
import { UNEXPECTED_ERROR_MESSAGE } from "../../constants/otherConstant";
import { COLORS, BOOK_CATEGORIES } from "constants";
import { createbook } from "service/BookService";

const Footer = ({ isLoader, checkValidatorOnAddBook, callCreateBook }) => {
  return (
    <Box p={4}>
      <Grid container justifyContent="flex-end">
        <Button
          variant="contained"
          disabled={isLoader.state}
          size="small"
          onClick={(e) => {
            checkValidatorOnAddBook() && callCreateBook();
          }}
        >
          <Typography variant="font14b" color={COLORS.white} mx={4} noWrap>
            Create Book
          </Typography>
          {isLoader.state && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                marginLeft: "-10px",
              }}
            />
          )}
        </Button>
      </Grid>
    </Box>
  );
};

function BookCreateModal({ open, projectId, fetchProjectDetails }) {
  const isLoader = useStateUpdate(false);
  const bookName = useStateUpdate("");
  const authorName = useStateUpdate("");
  const publisherName = useStateUpdate("");
  const bookCategory = useStateUpdate("");
  const validationError = useStateUpdate({
    bookName: "",
    authorName: "",
    publisherName: "",
    bookCategory: "",
  });
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);

  function checkValidatorOnAddBook() {
    const { state: bookNameValue } = bookName;
    const { state: authorNameValue } = authorName;
    const { state: publisherNameValue } = publisherName;
    const { state: bookCategoryValue } = bookCategory;

    const newValidationError = {
      bookName: "",
      authorName: "",
      publisherName: "",
      bookCategory: "",
    };

    if (isEmptyString(bookNameValue)) {
      newValidationError.bookName = "Book name is required";
    }
    if (isEmptyString(authorNameValue)) {
      newValidationError.authorName = "Author name is required";
    }
    if (isEmptyString(publisherNameValue)) {
      newValidationError.publisherName = "Publisher name is required";
    }
    if (isEmptyString(bookCategoryValue)) {
      newValidationError.bookCategory = "Book category is required";
    }

    validationError.update(newValidationError);
    return Object.values(newValidationError).every((value) => value === "");
  }

  const callCreateBook = async () => {
    try {
      isLoader.update(true);
      const data = getBookDetails();
      const response = await createbook(data);

      if (response.status === 200) {
        calltoast("success", "Book created");
        fetchProjectDetails();
        setTimeout(handleClose, 2000);
      } else {
        throw new Error(UNEXPECTED_ERROR_MESSAGE);
      }
    } catch (error) {
      calltoast("error", error.message || UNEXPECTED_ERROR_MESSAGE);
    }
  };

  const getBookDetails = () => ({
    bookName: bookName.state.trim(),
    authorName: authorName.state.trim(),
    publisherName: publisherName.state.trim(),
    bookCategory: bookCategory.state.trim(),
    projectId: projectId,
  });

  const calltoast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  const handleClose = () => {
    bookName.update("");
    authorName.update("");
    publisherName.update("");
    bookCategory.update("");
    validationError.update({
      bookName: "",
      authorName: "",
      publisherName: "",
      bookCategory: "",
    });
    isLoader.update(false);
    open.update(false);
  };

  return (
    <Fragment>
      <Modal open={open.state} onClose={handleClose}>
        <Box sx={smallModalStyle}>
          <Stack
            direction="row"
            mb={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="font20b">Create Book</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Paper variant={"outlined"}>
            <Box p={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    required
                    disabled={isLoader.state}
                    error={Boolean(validationError.state.bookName)}
                    helperText={validationError.state.bookName}
                    id="book-name"
                    label={<Typography variant="font15">Book name</Typography>}
                    color="primary"
                    fullWidth
                    value={bookName.state}
                    inputProps={{ maxLength: 50 }}
                    onChange={(e) => {
                      validationError.update({
                        ...validationError.state,
                        bookName: "",
                      });
                      bookName.update(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (isEmptyString(e.target.value)) {
                        validationError.update({
                          ...validationError.state,
                          bookName: "Book name is required",
                        });
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    disabled={isLoader.state}
                    error={Boolean(validationError.state.authorName)}
                    helperText={validationError.state.authorName}
                    id="author-name"
                    label={
                      <Typography variant="font15">Author name</Typography>
                    }
                    color="primary"
                    fullWidth
                    value={authorName.state}
                    inputProps={{ maxLength: 50 }}
                    onChange={(e) => {
                      validationError.update({
                        ...validationError.state,
                        authorName: "",
                      });
                      authorName.update(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (isEmptyString(e.target.value)) {
                        validationError.update({
                          ...validationError.state,
                          authorName: "Author name is required",
                        });
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    disabled={isLoader.state}
                    error={Boolean(validationError.state.publisherName)}
                    helperText={validationError.state.publisherName}
                    id="author-name"
                    label={
                      <Typography variant="font15">Publisher name</Typography>
                    }
                    color="primary"
                    fullWidth
                    value={publisherName.state}
                    inputProps={{ maxLength: 50 }}
                    onChange={(e) => {
                      validationError.update({
                        ...validationError.state,
                        publisherName: "",
                      });
                      publisherName.update(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (isEmptyString(e.target.value)) {
                        validationError.update({
                          ...validationError.state,
                          publisherName: "Author name is required",
                        });
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl
                    required
                    disabled={isLoader.state}
                    error={Boolean(validationError.state.bookCategory)}
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-label">
                      Category
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={bookCategory.state}
                      label="Category"
                      onChange={(e) => {
                        validationError.update({
                          ...validationError.state,
                          bookCategory: "",
                        });
                        bookCategory.update(e.target.value);
                      }}
                      onBlur={(e) => {
                        if (isEmptyString(e.target.value)) {
                          validationError.update({
                            ...validationError.state,
                            bookCategory: "Book category is required",
                          });
                        }
                      }}
                    >
                      <MenuItem value={BOOK_CATEGORIES.general}>
                        General
                      </MenuItem>
                      <MenuItem value={BOOK_CATEGORIES.poem}>Poem</MenuItem>
                    </Select>
                    <FormHelperText>
                      {validationError.state.bookCategory}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Footer
              {...{ isLoader, checkValidatorOnAddBook, callCreateBook }}
            />
          </Paper>
        </Box>
      </Modal>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Fragment>
  );
}

export default BookCreateModal;
