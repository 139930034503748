import React, { Fragment, useEffect } from "react";
import { Typography, Box, Grid, Stack } from "@mui/material";
import ProjectInformations from "./sections/ProjectInformations";
import { useStateUpdate } from "UseStateUpdate";
import {
  BookUploadModal,
  BookCreateModal,
  AssignAuthorModal,
} from "components/modals";
import { getProject, updateProject } from "service/ProjectService";
import { useParams } from "react-router-dom";
import { COLORS } from "constants";
import { BOOK_STATUS_CODES as BookStatusCodes } from "constants";
import AccessDenied from "components/common/AccessDenied";
import { useIsAuthorOrEngineer } from "utils/GetUserDetail";
import { updateBookStatus } from "service/BookService";

function ProjectDetails() {
  const isAuthorOrEngineer = useIsAuthorOrEngineer();
  const openCreateBookModal = useStateUpdate(false);
  const openUploadBookModal = useStateUpdate(false);
  const openAssignAuthorModal = useStateUpdate(false);
  const { projectId } = useParams();
  const projectDetails = useStateUpdate("");
  const isFetching = useStateUpdate(true);
  const isRefreshing = useStateUpdate(false);
  const haveBook = useStateUpdate(false);
  const bookDetails = useStateUpdate({});
  const currentBook = useStateUpdate(null);

  useEffect(() => {
    fetchProjectDetails();
  }, []);

  const fetchProjectDetails = async () => {
    isRefreshing.update(true);
    let result = await getProject(projectId);
    if (result.status === 200) {
      const data = result.data.data;
      projectDetails.update(data);
      if (data.books.length > 0) {
        haveBook.update(true);
        bookDetails.update(data.books[0]);
      }
    }
    isRefreshing.update(false);
    isFetching.update(false);
  };

  const callUpdateProject = async (projectId, newDetails) => {
    let result = await updateProject(projectId, newDetails);
    if (result.status === 200) {
      fetchProjectDetails();
    }
  };

  const callUpdateBookStatus = async () => {
    const body = { statusCode: BookStatusCodes.bookRecording };
    const result = await updateBookStatus(currentBook.state.bookId, body);
    result.status === 200 && fetchProjectDetails();
  };

  return isAuthorOrEngineer ? (
    <AccessDenied />
  ) : (
    <Box ml={{ xs: 2, md: 3 }} mr={{ xs: 2, md: 5 }}>
      {projectDetails.state && (
        <Fragment>
          <BookCreateModal
            open={openCreateBookModal}
            {...{ projectId, fetchProjectDetails }}
          />
          <BookUploadModal
            open={openUploadBookModal}
            bookId={bookDetails.state.bookId}
            fetchDetails={fetchProjectDetails}
          />
          <AssignAuthorModal
            {...{ openAssignAuthorModal, currentBook, callUpdateBookStatus }}
          />
        </Fragment>
      )}
      <Fragment>
        <Grid container mb={2}>
          <Grid item xs={6} md={4}>
            <Stack direction="row" spacing={1}>
              <Typography variant="font20b" color={COLORS.primary}>
                Project details
              </Typography>
            </Stack>
          </Grid>
        </Grid>

        <Grid container spacing={2} mb={2}>
          <Grid item xs={12}>
            {!isFetching.state && (
              <ProjectInformations
                {...{
                  isFetching,
                  projectDetails,
                  bookDetails,
                  haveBook,
                  openCreateBookModal,
                  openUploadBookModal,
                  openAssignAuthorModal,
                  fetchProjectDetails,
                  callUpdateProject,
                  isRefreshing,
                  currentBook,
                }}
              />
            )}
          </Grid>
        </Grid>
      </Fragment>
    </Box>
  );
}

export default ProjectDetails;
