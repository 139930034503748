import React from "react";
import { Grid, Box } from "@mui/material";
import { optimizePage } from "components/common/Styles";
import { getImageUrl } from "utils/GetUrl";

function PageImage({ pageImageUrl, bookId }) {
  return (
    <Grid item xs={1.5}>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          ...optimizePage.pageImage,
          backgroundImage: `url("${getImageUrl(pageImageUrl)}")`,
        }}
      />
    </Grid>
  );
}

export default PageImage;
