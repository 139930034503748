export const getFormattedDate = (timestamp) => {
  const date = new Date(timestamp);
  const formattedDate = date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  return formattedDate;
};

export const getFormattedDateAndTime = (timestamp) => {
  const date = new Date(timestamp);
  const currentDate = new Date();
  const formattedDate = date.toLocaleDateString("en-US", {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
  });
  const formattedTime = date.toLocaleTimeString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  let dayLabel = "";
  if (date.toDateString() === currentDate.toDateString()) {
    dayLabel = "Today";
  } else if (
    date.getDate() === currentDate.getDate() + 1 &&
    date.getMonth() === currentDate.getMonth() &&
    date.getFullYear() === currentDate.getFullYear()
  ) {
    dayLabel = "Tomorrow";
  }

  return `${dayLabel ? dayLabel + ", " : formattedDate} ${formattedTime}`;
};

export const getAudioPlayTime = (seconds) => {
  let sec_num = Math.floor(seconds);
  let hours = Math.floor(sec_num / 3600);
  let minutes = Math.floor((sec_num - hours * 3600) / 60);
  let secondsIs = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = `0${hours}`;
  }
  if (minutes < 10) {
    minutes = `0${minutes}`;
  }
  if (secondsIs < 10) {
    secondsIs = `0${secondsIs}`;
  }
  return `${hours}:${minutes}:${secondsIs}`;
};
