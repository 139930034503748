import React from "react";
import {
  Modal,
  Box,
  Grid,
  IconButton,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { largeModalStyle } from "components/common/Styles";
import { COLORS } from "constants";
import recordingPageScreen from "assets/images/recordingPageScreen.png";
import mic from "assets/images/mic.png";
import arrowDown from "assets/images/arrow-down-icon.png";
import arrowRight from "assets/images/arrow-right-icon.png";
import arrowLeft from "assets/images/arrow-left-icon.png";
import spaceBar from "assets/images/space-bar-icon.png";
import stopButton from "assets/images/stop_green.png";
import playButton from "assets/images/play-button.png";
import nextButton from "assets/images/recordNext.png";
import prevButton from "assets/images/recordPrev.png";
import doneButton from "assets/images/recordingpageProcced.png";
import videoSample from "assets/videos/myaudiobookrecorder.mp4";
import { useStateUpdate } from "UseStateUpdate";
import { primaryButtonHover } from "components/common/Styles";

function RecordGuideLine(props) {
  const { openGuideLineModal } = props;
  const showTutorial = useStateUpdate(false);

  const handleClose = () => {
    openGuideLineModal.update(false);
  };

  return (
    <Modal open={openGuideLineModal.state} onClose={handleClose}>
      <Box sx={largeModalStyle}>
        {/* <Grid container justifyContent="flex-end" px={1}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid> */}
        <Box width="100%" p={4}>
          <Stack spacing={1}>
            <Typography variant="font35b" color={COLORS.primary} align="center">
              Audio recording
            </Typography>
            <Typography variant="font32b" align="center">
              Guidelines
            </Typography>
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {/* <Typography
                variant="font15b"
                color="#007bff"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  showTutorial.update(!showTutorial.state);
                }}
              >
                {`${showTutorial.state ? "Show" : "Hide"} video tutorial`}
              </Typography> */}
              <Button
                variant="outlined"
                size="small"
                sx={primaryButtonHover}
                onClick={() => {
                  showTutorial.update(!showTutorial.state);
                }}
              >
                <Typography variant="font12b">{`${
                  showTutorial.state ? "Hide" : "Show"
                } video tutorial`}</Typography>
              </Button>
            </Box>
            {showTutorial.state && (
              <Box width="100%">
                <video
                  controls
                  width="100%"
                  style={{
                    boxShadow: "0 0 10px rgba(0, 0, 0, 0.25)",
                  }}
                >
                  <source src={videoSample} type="video/mp4" />
                </video>
              </Box>
            )}
            <Box width="100%">
              <img
                src={recordingPageScreen}
                width="100%"
                alt="recordingPageScreen"
              />
            </Box>
            <Typography variant="font25b">Shortcuts</Typography>
            <Grid container>
              {[
                {
                  src: spaceBar,
                  alt: "spaceBar",
                  width: "100%",
                  label: "Record/Stop",
                },
                {
                  src: arrowLeft,
                  alt: "arrowLeft",
                  width: "50%",
                  label: "Previous",
                },
                {
                  src: arrowRight,
                  alt: "arrowRight",
                  width: "50%",
                  label: "Next",
                },
                {
                  src: arrowDown,
                  alt: "arrowDown",
                  width: "50%",
                  label: "Play/Pause",
                },
              ].map((item, index) => (
                <Grid
                  key={item.src}
                  item
                  xs={3}
                  container
                  justifyContent="center"
                  alignItems="center"
                >
                  <Stack justifyContent="center" alignItems="center">
                    <img src={item.src} width={item.width} alt={item.alt} />
                    <Typography variant="font15">{item.label}</Typography>
                  </Stack>
                </Grid>
              ))}
            </Grid>
            <Typography variant="font25b">1. Pages:</Typography>
            <Typography variant="font20" pl={5}>
              The left side contains all the pages you have uploaded.
            </Typography>
            <Typography variant="font25b">2. Paragraphs:</Typography>
            <Typography variant="font20" pl={5}>
              The right side lists all the available paragraphs from the
              selected page.
            </Typography>{" "}
            <Typography variant="font25b">3. Main content:</Typography>
            <Typography variant="font20" pl={5}>
              The text area in the centre shows the selected paragraph for
              recording.
            </Typography>{" "}
            <Typography variant="font25b">4. Start record:</Typography>
            <Typography variant="font20" pl={5}>
              {"Click on the mic button  "}
              <img src={mic} width="25px" alt="mic" />
              {" to start recording the audio."}
            </Typography>
            <Typography variant="font25b">5. Stop recording:</Typography>
            <Typography variant="font20" pl={5}>
              {"If you are done with the recording press the stop button "}
              <img src={stopButton} width="25px" alt="stopButton" />
              {" to save the audio."}
            </Typography>{" "}
            <Typography variant="font25b">6. Complete uploading:</Typography>
            <Typography variant="font20" pl={5}>
              After uploading an audio of a paragraph, a green tick mark will
              appear on the selected paragraph on the paragraph list. Same in
              the case of pages.
            </Typography>
            <Typography variant="font25b">7. Play audio:</Typography>
            <Typography variant="font20" pl={5}>
              {"Press the play button "}
              <img src={playButton} width="25px" alt="playButton" />
              {
                " below the content to listen to the audio that you have recorded just now."
              }
            </Typography>{" "}
            <Typography variant="font25b">8. Re-record audio:</Typography>
            <Typography variant="font20" pl={5}>
              {
                "If you didn’t like what has been recorded press the mic button "
              }
              <img src={mic} width="25px" alt="mic" />
              {" to re-record the audio."}
            </Typography>{" "}
            <Typography variant="font25b">9. Next:</Typography>
            <Typography
              variant="font20"
              pl={5}
              display="flex"
              alignItems="center"
            >
              {"Press the next button to move to the next content. "}
              <img src={nextButton} width="15%" alt="nextButton" />
            </Typography>{" "}
            <Typography variant="font25b">10. Prev:</Typography>
            <Typography
              variant="font20"
              pl={5}
              display="flex"
              alignItems="center"
            >
              {"Press the prev button to move to the previous content. "}
              <img src={prevButton} width="15%" alt="prevButton" />
            </Typography>{" "}
            <Typography variant="font25b">11. Complete recording:</Typography>
            <Typography variant="font20" pl={5}>
              {
                "If you have finished recording all the pages click done with recording. "
              }
              <img src={doneButton} width="25%" alt="doneButton" />
            </Typography>{" "}
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}

export default RecordGuideLine;
