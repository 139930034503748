import React, { Fragment, useEffect } from "react";
import { Stack, Typography, Box, Grid, Paper, Divider } from "@mui/material";
import { COLORS } from "constants";
import ProfilePicture from "./sections/ProfilePicture";
import AccessDenied from "components/common/AccessDenied";
import UserBookTable from "./sections/UserBookTable";

import { useStateUpdate } from "UseStateUpdate";
import { getUserDetail } from "service/UserService";
import { useParams } from "react-router-dom";
import { useIsAuthorOrEngineer } from "utils/GetUserDetail";

const renderName = (name) => {
  return (
    <Grid container>
      <Grid container item xs={12}>
        <Typography variant="font14b">{name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

const renderEmail = (email) => {
  return (
    <Grid container>
      <Grid container item xs={12}>
        <Typography variant="font14">{email}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

const renderPhoneNumber = (phoneNumber) => {
  return (
    <Grid container>
      <Grid container item xs={12}>
        <Typography variant="font14">{phoneNumber}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

function UserProfile() {
  const { userId } = useParams();
  const isAuthorOrEngineer = useIsAuthorOrEngineer();
  const booksList = useStateUpdate([]);
  const userDetail = useStateUpdate(null);

  useEffect(() => {
    !isAuthorOrEngineer && fetchUserDetails();
  }, []);

  useEffect(() => {
    userDetail.state && booksList.update(userDetail.state.books ?? []);
  }, [userDetail.state]);

  const fetchUserDetails = async () => {
    const result = await getUserDetail(userId);
    if (result.status === 200) {
      const { data } = result.data;
      userDetail.update(data);
    }
  };

  return isAuthorOrEngineer ? (
    <AccessDenied />
  ) : (
    <Box ml={{ xs: 2, md: 3 }} mr={{ xs: 2, md: 5 }}>
      <Stack spacing={2}>
        <Typography variant="font20b" color={COLORS.primary}>
          Author profile
        </Typography>
        <Grid>
          {userDetail.state && (
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper variant="outlined">
                  <Grid container>
                    <Grid
                      container
                      item
                      xs={12}
                      md={4}
                      py={3}
                      justifyContent="center"
                    >
                      <ProfilePicture isViewUser profileData={userDetail} />
                    </Grid>
                    <Divider orientation="vertical" variant="middle" flexItem />
                    <Grid
                      container
                      item
                      xs={12}
                      md={7}
                      p={4}
                      direction="column"
                      justifyContent="space-around"
                    >
                      {renderName(
                        `${userDetail.state.firstName} ${userDetail.state.lastName}`
                      )}
                      {renderEmail(userDetail.state.email)}
                      {renderPhoneNumber(userDetail.state.phone)}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          )}
        </Grid>
        {userDetail.state?.roleId !== 1 && (
          <Fragment>
            <Typography variant="font20b" color={COLORS.primary}>
              Books assigned
            </Typography>
            <Grid container>
              <UserBookTable {...{ booksList }} />
            </Grid>
          </Fragment>
        )}
      </Stack>
    </Box>
  );
}

export default UserProfile;
