import React from "react";
import { Modal, Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { largeModalStyle } from "components/common/Styles";
import { COLORS } from "constants";
import verifybookScreenshot from "assets/images/verify-book-screenshot.png";
import AddParagraph from "assets/images/add-file.png";
import MergeParagraph from "assets/images/file.png";
import DeleteParagraph from "assets/images/file-delete.png";
import ToggleOff from "assets/images/switch-off.png";
import GroupPageButton from "assets/images/GroupPageButton.png";

function VerifyPageGuideLine(props) {
  const { openGuideLineModal } = props;

  const handleClose = () => {
    openGuideLineModal.update(false);
  };

  return (
    <Modal open={openGuideLineModal.state} onClose={handleClose}>
      <Box sx={largeModalStyle}>
        <Grid container justifyContent="flex-end" px={1}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Box width="100%" p={4}>
          <Stack spacing={2}>
            <Typography variant="font35b" color={COLORS.primary} align="center">
              Verify book
            </Typography>
            <Typography variant="font32b" align="center">
              Guidelines
            </Typography>
            <Box width="100%">
              <img
                src={verifybookScreenshot}
                width="100%"
                alt="verifybookScreenshot"
              />
            </Box>
            <Typography variant="font25b">1. Page view:</Typography>
            <Typography variant="font20" pl={5}>
              In the left side, you can see the pages that you have uploaded.
            </Typography>
            <Typography variant="font25b">2. Paragraph view:</Typography>
            <Typography variant="font20" pl={5}>
              In the right-hand side, you can view the content of the pdf which
              are ready to be edited inside a text area.
            </Typography>
            <Typography variant="font25b">3. Add paragraph:</Typography>
            <Typography variant="font20" pl={5}>
              {"Click on the add a paragraph button "}
              <img src={AddParagraph} width="25px" alt="AddParagraph" />
              to create a new paragraph.
            </Typography>
            <Typography variant="font25b">4. Merge paragraph:</Typography>
            <Typography variant="font20" pl={5}>
              {"Click on the merge button "}
              <img src={MergeParagraph} width="25px" alt="MergeParagraph" />
              to merge the paragraph with the paragraph above it.
            </Typography>
            <Typography variant="font25b">5. Delete paragraph:</Typography>
            <Typography variant="font20" pl={5}>
              {
                "You can delete a paragraph by clicking on the trash can icon.  "
              }
              <img src={DeleteParagraph} width="25px" alt="DeleteParagraph" />
            </Typography>
            <Typography variant="font25b">6. Verify page:</Typography>
            <Typography variant="font20" pl={5}>
              {"If everything is looking good, click on the toggle  "}
              <img src={ToggleOff} width="25px" alt="ToggleOff" />
              button on the page to verify.
            </Typography>
            <Typography variant="font25b">7. Verify book:</Typography>
            <Typography variant="font20" pl={5}>
              If you are done with everything, click on group pages. The button
              is enabled only after verifying each page.
              <img src={GroupPageButton} width="25%" alt="GroupPageButton" />
            </Typography>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
}

export default VerifyPageGuideLine;
