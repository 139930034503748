import React from "react";
import { Modal, Box, Stack, Typography, Button } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { smallModalStyle } from "components/common/Styles";
import { primaryButtonHover } from "components/common/Styles";

function NoisyModal(props) {
  const { showNoisyModal, noisyModalMessage, noisyModalType } = props;

  return (
    <Modal open={showNoisyModal.state}>
      <Box sx={{ ...smallModalStyle, p: 0 }}>
        <Stack spacing={4} alignItems="center" mt={3}>
          {noisyModalType.state === "success" ? (
            <CheckCircleOutlineIcon
              color={noisyModalType.state}
              sx={{ fontSize: "75px" }}
            />
          ) : (
            <ErrorOutlineIcon
              color={noisyModalType.state}
              sx={{ fontSize: "75px" }}
            />
          )}
          <Box bgcolor="#eaeff1" width={"100%"} p={4}>
            <Stack spacing={2} alignItems="center">
              <Typography variant="font17" textAlign="center">
                {noisyModalMessage.state}
              </Typography>
              <Button
                variant="outlined"
                size="small"
                sx={primaryButtonHover}
                onClick={() => showNoisyModal.update(false)}
              >
                <Typography variant="font15" noWrap>
                  OK
                </Typography>
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
}

export default NoisyModal;
