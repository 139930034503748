import jwt_decode from "jwt-decode";
import { updateToken } from "./AuthService";
import { decryptData } from "utils/EncryptDecryptLocal";
import { encryptData } from "utils/EncryptDecryptLocal";

let token = decryptData("token") ?? null;

export const getExpirationDate = (jwtToken) => {
  if (!jwtToken) {
    return null;
  }
  let decoded = jwt_decode(jwtToken);
  return decoded.exp * 1000;
};

const isExpired = (exp) => {
  if (!exp) {
    return false;
  }

  return Date.now() > exp;
};

export const getToken = async () => {
  if (!token) {
    return null;
  }
  if (isExpired(getExpirationDate(token.accessToken))) {
    const response = await updateToken(token.refreshToken);
    let updatedToken = null;
    if (response.status === 200) {
      updatedToken = {
        accessToken: response.data.data.accessToken,
        refreshToken: response.data.data.refreshToken,
      };
    } else {
      localStorage.removeItem("user");
    }
    setToken(updatedToken);
    return response.data.data.accessToken;
  }

  return token.accessToken;
};

export const isLoggedIn = () => {
  return !!token;
};

let observers = [];

export const subscribe = (observer) => {
  observers.push(observer);
};

export const unsubscribe = (observer) => {
  observers = observers.filter((_observer) => _observer !== observer);
};

const notify = () => {
  const isLogged = isLoggedIn();
  observers.forEach((observer) => observer(isLogged));
};

export const setToken = (newToken) => {
  if (newToken) {
    encryptData("token", newToken);
  } else {
    localStorage.removeItem("token");
  }
  token = newToken;
  notify();
};
