import React, { Fragment } from "react";
import {
  Grid,
  Typography,
  Input,
  CircularProgress,
  Paper,
  Box,
  Stack,
  Button,
  Modal,
} from "@mui/material/";
import { smallModalStyle } from "../common/Styles";
import { useStateUpdate } from "UseStateUpdate";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { COLORS } from "constants";
import { UNEXPECTED_ERROR_MESSAGE } from "../../constants/otherConstant";
import { uploadChapterAudio } from "service/BookService";

const UploadBook = ({ handleFileChange }) => {
  return (
    <Fragment>
      <Grid container justifyContent="center" item xs={12}>
        <Typography variant="font17">Select optimized audio here</Typography>
      </Grid>
      <Grid container justifyContent="center" item xs={12}>
        <Input
          type="file"
          fullWidth
          inputProps={{ accept: "audio/*" }}
          onChange={handleFileChange}
          style={{
            backgroundColor: "#273891",
            color: COLORS.white,
            paddingLeft: "10px",
          }}
        />
        <Typography variant="font14" mt={2}>
          Please upload optimized audio file
        </Typography>
      </Grid>
    </Fragment>
  );
};

const UploadingBook = () => {
  return (
    <Fragment>
      <Grid container justifyContent="center" item xs={12}>
        <Typography variant="font17">Preparing audio upload</Typography>
      </Grid>
      <Grid container justifyContent="center" item xs={12}>
        <Typography variant="font17">
          <CircularProgress />
        </Typography>
      </Grid>
      <Grid container justifyContent="center" item xs={12}>
        <Typography variant="font17">Please wait</Typography>
      </Grid>
    </Fragment>
  );
};

const UploadingFailed = ({ handleClose, handleTryAgain }) => {
  return (
    <Fragment>
      <Paper variant={"outlined"}>
        <Box py={4}>
          <Stack spacing={4} alignItems="center">
            <ErrorOutlineOutlinedIcon
              color="error"
              style={{ fontSize: "100px" }}
            />
            <Stack spacing={1} alignItems="center">
              <Typography variant="font17" style={{ textAlign: "center" }}>
                An error occurred.
              </Typography>
              <Typography variant="font17" style={{ textAlign: "center" }}>
                Unable to process your request at the moment.
              </Typography>
            </Stack>
            <Stack direction="row" spacing={3} alignItems="center">
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={handleTryAgain}
              >
                <Typography variant="font12b" mx={4}>
                  Try again
                </Typography>
              </Button>
              <Button
                variant="outlined"
                size="small"
                color="primary"
                onClick={handleClose}
              >
                <Typography variant="font12b" mx={4}>
                  Close
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Paper>
    </Fragment>
  );
};

function AudioUploadModal(props) {
  const {
    open,
    bookId,
    chapterId,
    fetchDetails,
    severity,
    toastMessage,
    toastOpen,
  } = props;
  const isUploading = useStateUpdate(false);
  const isUploadingFailed = useStateUpdate(false);

  const handleClose = () => {
    isUploading.update(false);
    isUploadingFailed.update(false);
    calltoast("success", "Audio uploaded");
    open.update(false);
  };

  const handleTryAgain = () => {
    isUploading.update(false);
    isUploadingFailed.update(false);
  };

  const handleFileChange = async (event) => {
    isUploading.update(true);
    let files = event.target.files || event.dataTransfer.files;
    let formData = new FormData();
    formData.append("optimizedAudio", files[0]);
    let result = await uploadChapterAudio(bookId, chapterId, formData);
    if (result.status === 200) {
      setTimeout(handleClose, 2000);
      fetchDetails();
    } else {
      isUploadingFailed.update(true);
      calltoast("error", UNEXPECTED_ERROR_MESSAGE);
    }
  };

  const calltoast = async (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  return (
    <Fragment>
      <Modal open={open.state} onClose={handleClose}>
        <Box sx={smallModalStyle}>
          <Box ml={{ xs: 2, md: 3 }} mr={{ xs: 2, md: 5 }}>
            {isUploadingFailed.state ? (
              <UploadingFailed {...{ handleTryAgain, handleClose }} />
            ) : (
              <Fragment>
                <Grid mb={2}>
                  <Typography variant="font20b" color={COLORS.primary}>
                    Upload optimized audio
                  </Typography>
                </Grid>
                <Paper variant={"outlined"}>
                  <Box p={4}>
                    <Grid spacing={4} container>
                      {isUploading.state ? (
                        <UploadingBook />
                      ) : (
                        <UploadBook handleFileChange={handleFileChange} />
                      )}
                    </Grid>
                  </Box>
                </Paper>
              </Fragment>
            )}
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
}

export default AudioUploadModal;
