import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Stack, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { easeQuadInOut } from "d3-ease";
import AnimatedProgressProvider from "./AnimatedProgressProvider";
import { COLORS } from "constants";
import { useNavigate } from "react-router-dom";

function StatusProgress({
  percentage,
  pathColor,
  count,
  status,
  isRecordingStatus,
  bookId,
  label,
}) {
  let navigate = useNavigate();

  return (
    <Stack spacing={2} alignItems="center">
      <Stack alignItems="center">
        <Box
          sx={{
            width: 130,
          }}
        >
          <AnimatedProgressProvider
            valueStart={0}
            valueEnd={percentage * 100}
            duration={2.0}
            easingFunction={easeQuadInOut}
          >
            {(value) => {
              return (
                <Link
                  href=""
                  onClick={(e) => {
                    e.preventDefault();
                    if (isRecordingStatus) {
                      navigate(`/voices/${bookId}`);
                    } else {
                      count && navigate(`/books?status=${status}`);
                    }
                  }}
                >
                  <CircularProgressbar
                    value={value}
                    text={`${count}`}
                    circleRatio={0.75}
                    styles={buildStyles({
                      textColor: COLORS.statusText,
                      pathColor: pathColor,
                      trailColor: "",
                      rotation: 1 / 2 + 1 / 8,
                      strokeLinecap: "butt",
                    })}
                  />
                </Link>
              );
            }}
          </AnimatedProgressProvider>
        </Box>{" "}
        {isRecordingStatus && (
          <Typography
            variant="font13b"
            textAlign="center"
            color={COLORS.primary}
            noWrap
          >
            {label}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

export default StatusProgress;
