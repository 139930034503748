import React, { useContext } from "react";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { groupingChaptersStyles } from "components/common/Styles";
import Reset from "assets/images/editChapter.png";
import resetIcon from "assets/images/reset.png";
import { GroupingPageContext } from "context/context";

function GroupingLeftScrollable() {
  const { chapters, showChapterPages, setConfirmModalData, callResetChapters } =
    useContext(GroupingPageContext);
  const classes = groupingChaptersStyles();

  const getPages = (pages) => {
    const [firstPage, lastPage] = [pages[0], pages[pages.length - 1]];
    return `Page ${firstPage.pageNumber} - Page ${lastPage.pageNumber}`;
  };

  return (
    <Box marginTop={10}>
      {chapters.state.map((chapter, index) => (
        <Box
          key={chapter.chapterId}
          className={classes.cards}
          p={0.5}
          onClick={() => {
            showChapterPages(index);
          }}
        >
          <Grid container py={2} pl={1}>
            <Grid item xs={12}>
              <Typography variant="font15b">{chapter.chapterName}</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="font12">
                {getPages(chapter.pages)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              {index > 0 && index < chapters.state.length - 1 && (
                <Tooltip
                  title={
                    index === chapters.state.length - 2
                      ? `Reset ${chapter.chapterName}`
                      : `Reset from ${chapter.chapterName} to ${
                          chapters.state[chapters.state.length - 2].chapterName
                        }`
                  }
                >
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      setConfirmModalData(
                        resetIcon,
                        "Reset Chapters",
                        index === chapters.state.length - 2
                          ? `Are you sure you want to resets ${chapter.chapterName}?`
                          : `Are you sure you want to reset from ${
                              chapter.chapterName
                            } to ${
                              chapters.state[chapters.state.length - 2]
                                .chapterName
                            }?`,
                        "Yes, reset chapters",
                        "Cancel, keep chapters",
                        () => {
                          callResetChapters(
                            chapter.chapterNumber,
                            chapters.state[chapters.state.length - 2]
                              .chapterNumber
                          );
                        },
                        null
                      );
                    }}
                  >
                    <img src={Reset} style={{ width: "15px" }} alt="reset" />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
}

export default GroupingLeftScrollable;
