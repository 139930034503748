import {
  Stack,
  Box,
  Typography,
  useMediaQuery,
  Container,
  Grid,
  Chip,
} from "@mui/material";
import { COLORS } from "constants";
import downNext from "assets/images/downNext.png";

const RenderMainButton = ({ label, divToScroll, scrollTo }) => {
  return (
    <Chip
      label={
        <Typography variant={"font15"} color={COLORS.white}>
          {label}
        </Typography>
      }
      variant="outlined"
      sx={{ marginBottom: 2, borderRadius: "5px" }}
      onClick={() => {
        divToScroll.update(scrollTo);
      }}
    />
  );
};

const RenderDivider = () => {
  return (
    <Chip
      label={
        <Typography variant={"font20"} color={COLORS.white}>
          {">"}
        </Typography>
      }
      variant="outlined"
      sx={{ border: "none", marginBottom: 2 }}
    />
  );
};

const RenderNext = () => {
  return (
    <Chip
      label={<img src={downNext} width="15px" alt="next" />}
      variant="outlined"
      sx={{ border: "none" }}
    />
  );
};

const RenderSubButton = ({ label, divToScroll, scrollTo }) => {
  return (
    <Chip
      label={
        <Typography variant={"font15"} color={COLORS.white}>
          {label}
        </Typography>
      }
      variant="outlined"
      sx={{ marginBottom: 2 }}
      onClick={() => {
        divToScroll.update(scrollTo);
      }}
    />
  );
};

function HowToBuild({ divToScroll }) {
  const isXs = useMediaQuery((theme) => theme.breakpoints.only("xs"));

  return (
    <Box>
      <Box
        sx={{
          background: `linear-gradient(0deg, ${COLORS.primary} 30%, #7782ba 100%)`,
        }}
        py={5}
      >
        <Container maxWidth="md">
          <Stack>
            <Typography
              variant={"font24b"}
              textAlign={{ xs: "center", md: "left" }}
            >
              The step-by-step guide on
            </Typography>
            <Typography
              variant={"font55b"}
              textAlign={{ xs: "center", md: "left" }}
              color={COLORS.white}
            >
              How to build an audiobook
            </Typography>
            <Typography
              variant={"font16"}
              textAlign={{ xs: "center", md: "left" }}
              color={COLORS.white}
            >
              My Audio Book Recorder helps you to create your audiobook from
              scratch in the easiest way possible. There are three steps we
              encourage you to follow to get there and beyond, viz, setup,
              record and publish. The flow is explained below in detail.
            </Typography>
          </Stack>
          {isXs ? (
            <Grid container spacing={2} mt={3}>
              <Grid container justifyContent="center" item xs={12}>
                <RenderMainButton
                  label="Setup"
                  {...{ divToScroll }}
                  scrollTo="setup"
                />
                <RenderDivider />
                <RenderMainButton
                  label="Record"
                  {...{ divToScroll }}
                  scrollTo="record"
                />
                <RenderDivider />
                <RenderMainButton
                  label="Publish"
                  {...{ divToScroll }}
                  scrollTo="publish"
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container spacing={2} mt={5}>
              <Grid item xs={12}>
                <RenderMainButton
                  label="Setup"
                  {...{ divToScroll }}
                  scrollTo="setup"
                />
                <RenderDivider />
                <RenderSubButton
                  label="Room"
                  {...{ divToScroll }}
                  scrollTo="room"
                />
                <RenderDivider />
                <RenderSubButton
                  label="Equipment"
                  {...{ divToScroll }}
                  scrollTo="mic"
                />
                <RenderDivider />
                <RenderSubButton
                  label="Integration"
                  {...{ divToScroll }}
                  scrollTo="integration"
                />
                <RenderDivider />
                <RenderSubButton
                  label="Sample recording"
                  {...{ divToScroll }}
                  scrollTo="sampleRecording"
                />
              </Grid>
              <Grid item xs={12} ml={2}>
                <RenderNext />
              </Grid>
              <Grid item xs={12}>
                <RenderMainButton
                  label="Record"
                  {...{ divToScroll }}
                  scrollTo="record"
                />
                <RenderDivider />
                <RenderSubButton
                  label="Recording"
                  {...{ divToScroll }}
                  scrollTo="recordAudio"
                />
                <RenderDivider />
                <RenderSubButton
                  label="Audio scrubbing"
                  {...{ divToScroll }}
                  scrollTo="scrubAudio"
                />
              </Grid>
              <Grid item xs={12} ml={2}>
                <RenderNext />
              </Grid>
              <Grid item xs={12}>
                <RenderMainButton
                  label="Publish"
                  {...{ divToScroll }}
                  scrollTo="publish"
                />
                {/* <Render  Divider /> */}
                {/* <RenderSubButton
                  label="Book cover creation"
                  {...{ divToScroll }}
                  scrollTo="bookCover"
                /> */}
                {/* <RenderDivider /> */}
                {/* <RenderSubButton
                  label="Explore ACX"
                  {...{ divToScroll }}
                  scrollTo="exploreACX"
                />
                <RenderDivider /> */}
                {/* <RenderSubButton
                  label="Upload to ACX"
                  {...{ divToScroll }}
                  scrollTo="uploadACX"
                /> */}
              </Grid>
            </Grid>
          )}
        </Container>
      </Box>
      <Box py={5}>
        <Container maxWidth="md">
          <Stack spacing={3}>
            <Typography variant={"font30b"}>
              What makes My Audio Book Recorder different?
            </Typography>
            <Typography
              variant={"font18"}
              lineHeight="30px"
              style={{ textAlign: "justify" }}
            >
              It is an all in one audiobook production system. It enables both
              authors and narrators to create audiobooks seamlessly and
              cost-effectively. You can start projects in your convenient
              environment.
            </Typography>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}

export default HowToBuild;
