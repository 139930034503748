const DEV_BASE_URL = "https://api.dev.myaudiobookrecorder.com/";
const PROD_BASE_URL = "https://api.myaudiobookrecorder.com/";

const DEV_IMAGE_BASE_URL =
  "https://audifyzb2b-book-store-dev.s3.us-west-2.amazonaws.com/";
const PROD_IMAGE_BASE_URL =
  "https://myaudiobookrecorder-book-store.s3.us-west-2.amazonaws.com/";

export const BASE_PATH =
  process.env.REACT_APP_ENV === "dev" ? DEV_BASE_URL : PROD_BASE_URL;

export const BASE_IMAGE_PATH =
  process.env.REACT_APP_ENV === "dev"
    ? DEV_IMAGE_BASE_URL
    : PROD_IMAGE_BASE_URL;
