import { createTheme } from "@mui/material";
import { COLORS } from "constants";

export const basetheme = createTheme({
  palette: {
    primary: { main: COLORS.primary },
    secondary: { main: COLORS.secondary },
    white: { main: COLORS.white },
  },
  typography: {
    buttonLabel: {
      fontWeight: "700",
      fontSize: "14px",
      textTransform: "none",
      display: "block",
      color: COLORS.primary,
    },
    containedbuttonLabel: {
      fontWeight: "700",
      fontSize: "14px",
      textTransform: "none",
      display: "block",
      color: COLORS.white,
    },
    ...[
      90, 55, 48, 40, 35, 32, 31, 30, 29, 27, 26, 25, 24, 23, 21, 20, 19, 18,
      17, 16, 15, 14, 13, 12, 11, 10, 8,
    ].reduce(
      (acc, size) => ({
        ...acc,
        [`font${size}`]: {
          fontWeight: "400",
          textTransform: "none",
          fontSize: `${size}px`,
        },
        [`font${size}b`]: {
          fontWeight: "700",
          textTransform: "none",
          fontSize: `${size}px`,
        },
      }),
      {}
    ),
  },
});
