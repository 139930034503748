import React, { useEffect } from "react";
import { Stack, Typography, Box, Grid, Paper, Divider } from "@mui/material";
import { COLORS } from "constants";
import ProfilePicture from "./sections/ProfilePicture";
import Name from "./sections/Name";
import Email from "./sections/Email";
import PhoneNumber from "./sections/PhoneNumber";
import Password from "./sections/Password";
import { useIsAuthorOrEngineer } from "utils/GetUserDetail";
import { useStateUpdate } from "UseStateUpdate";
import Toast from "components/common/Toast";
import { getProfileData } from "service/UserService";

function Profile({ isHideSideMenu }) {
  const isAuthorOrEngineer = useIsAuthorOrEngineer();
  const profileData = useStateUpdate();

  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(true);

  useEffect(() => {
    fetchProfileData();
    return () => {};
  }, []);

  useEffect(() => {
    isHideSideMenu.update(isAuthorOrEngineer);
  }, [isAuthorOrEngineer]);

  const fetchProfileData = async () => {
    const result = await getProfileData();
    if (result.status === 200) {
      calltoast("success", "Name changed");
      profileData.update(result.data.data);
    }
  };

  const calltoast = async (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  return (
    <Box
      mx={{ xs: 2, md: isAuthorOrEngineer ? 10 : 5 }}
      my={isAuthorOrEngineer && 5}
    >
      <Stack spacing={2}>
        <Typography variant="font20b" color={COLORS.primary}>
          Profile
        </Typography>
        <Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper variant="outlined">
                <Grid container>
                  <Grid container item xs={12} py={3} justifyContent="center">
                    <ProfilePicture {...{ fetchProfileData, profileData }} />
                  </Grid>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  <Stack spacing={4} width="100%" p={4}>
                    <Name {...{ profileData, fetchProfileData, calltoast }} />
                    <Email {...{ profileData }} />
                    <PhoneNumber
                      {...{ profileData, fetchProfileData, calltoast }}
                    />
                    <Password {...{ calltoast }} />
                  </Stack>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Toast
          severity={severity.state}
          toastMessage={toastMessage.state}
          toastOpen={toastOpen.state}
        />
      </Stack>
    </Box>
  );
}

export default Profile;
