import React, { Fragment, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Grid,
  Divider,
  Paper,
  IconButton,
  Modal,
  Button,
  CircularProgress,
  Autocomplete,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { smallModalStyle } from "components/common/Styles";
import { isEmptyString } from "utils/FormValidator";
import { useStateUpdate } from "UseStateUpdate";
import Toast from "components/common/Toast";
import { UNEXPECTED_ERROR_MESSAGE } from "../../constants/otherConstant";
import { THREAD_TYPES as threadTypes, COLORS } from "constants";
import { sendComment } from "service/ChatService";
import { listUsers } from "service/UserService";
import { capitalize } from "../../utils/WordsUtils";
import { getUserId, useIsAuthorOrEngineer } from "../../utils/GetUserDetail";

function CreateThreadModal({
  open,
  userId,
  threadTitle,
  description,
  message,
  fetchThreads,
  threadPage,
  threadParagraph,
  bookId,
}) {
  const isLoader = useStateUpdate(false);
  const isAuthorOrEngineer = useIsAuthorOrEngineer(false);
  const validationError = useStateUpdate({
    threadTitle: "",
    description: "",
    message: "",
  });
  const severity = useStateUpdate("success");
  const toastMessage = useStateUpdate("");
  const toastOpen = useStateUpdate(false);
  const isFetchingAuthors = useStateUpdate(true);
  const dropDownOptions = useStateUpdate(null);
  const assignedAuthor = useStateUpdate(null);
  const authorsList = useStateUpdate(null);
  const selectedUserId = useStateUpdate(null);

  useEffect(() => {
    !userId && !isAuthorOrEngineer && fetchAuthors();
  }, []);

  useEffect(() => {
    authorsList.state &&
      dropDownOptions.update(
        authorsList.state.map((author) => {
          return {
            label: `${capitalize(author.firstName)} ${capitalize(
              author.lastName
            )} (${author.email})`,
            userId: author.userId,
          };
        })
      );
  }, [authorsList.state]);

  const fetchAuthors = async () => {
    let result = await listUsers("authors");
    if (result.status === 200) {
      authorsList.update(result.data.data.users);
      isFetchingAuthors.update(false);
    }
  };

  const callCreateThread = async () => {
    isLoader.update(true);
    let data = getThreadDetails();
    let response = await sendComment(data);
    if (response.status === 200) {
      calltoast("success", "Thread created");
      setTimeout(handleClose, 1500);
      !userId && fetchThreads();
    } else {
      calltoast("error", UNEXPECTED_ERROR_MESSAGE);
    }
  };

  const getThreadDetails = () => {
    const receiverUserId = isAuthorOrEngineer
      ? getUserId()
      : userId ?? selectedUserId.state;
    return {
      title: threadTitle.state.trim(),
      description: description.state.trim(),
      message: message.state.trim(),
      receiverUserId,
      threadType: threadTypes.other,
      pageUri: bookId
        ? `/voices/${bookId}?page=${threadPage.state}&paragraph=${threadParagraph.state}`
        : ``,
    };
  };

  function checkValidatorOnAddThread() {
    const { state: threadTitleValue } = threadTitle;
    const { state: descriptionValue } = description;
    const { state: messageValue } = message;

    const newValidationError = {
      threadTitle: "",
      description: "",
      message: "",
    };

    newValidationError.threadTitle = isEmptyString(threadTitleValue)
      ? "Title is required"
      : "";
    newValidationError.description = isEmptyString(descriptionValue)
      ? "Description is required"
      : "";
    newValidationError.message = isEmptyString(messageValue)
      ? "Message cant be empty"
      : "";

    validationError.update(newValidationError);
    return Object.values(newValidationError).every((value) => value === "");
  }

  const calltoast = (severityIs, messageIs) => {
    severity.update(severityIs);
    toastMessage.update(messageIs);
    toastOpen.update(true);
  };

  const handleClose = () => {
    threadTitle.update("");
    description.update("");
    assignedAuthor.update(null);
    message.update("");
    validationError.update({
      threadTitle: "",
      description: "",
      message: "",
    });
    isLoader.update(false);
    open.update(false);
  };

  const handleChange = (event, newValue) => {
    assignedAuthor.update(newValue);
    selectedUserId.update(newValue ? newValue.userId : null);
  };

  const disableButton = () => {
    return (
      isLoader.state ||
      (!userId && !assignedAuthor.state && !isAuthorOrEngineer)
    );
  };

  return (
    <Fragment>
      <Modal open={open.state} onClose={handleClose}>
        <Box sx={smallModalStyle}>
          <Stack
            direction="row"
            mb={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="font20b">Create message thread</Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Paper variant={"outlined"}>
            <Box p={4}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {!userId && !isAuthorOrEngineer && (
                    <Autocomplete
                      options={dropDownOptions.state}
                      value={assignedAuthor.state}
                      renderInput={(params) => (
                        <TextField {...params} label="Author" />
                      )}
                      onChange={handleChange}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    disabled={isLoader.state}
                    error={Boolean(validationError.state.threadTitle)}
                    helperText={validationError.state.threadTitle}
                    id="threadTitle"
                    label={<Typography variant="font15">Title</Typography>}
                    color="primary"
                    fullWidth
                    value={threadTitle.state}
                    inputProps={{ maxLength: 50 }}
                    onChange={(e) => {
                      validationError.update({
                        ...validationError.state,
                        threadTitle: "",
                      });
                      threadTitle.update(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (isEmptyString(e.target.value)) {
                        validationError.update({
                          ...validationError.state,
                          threadTitle: "Title is required",
                        });
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    disabled={isLoader.state}
                    error={Boolean(validationError.state.description)}
                    helperText={validationError.state.description}
                    id="description"
                    label={
                      <Typography variant="font15">Description</Typography>
                    }
                    color="primary"
                    fullWidth
                    value={description.state}
                    inputProps={{ maxLength: 50 }}
                    onChange={(e) => {
                      validationError.update({
                        ...validationError.state,
                        description: "",
                      });
                      description.update(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (isEmptyString(e.target.value)) {
                        validationError.update({
                          ...validationError.state,
                          description: "Description is required",
                        });
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    required
                    disabled={isLoader.state}
                    error={Boolean(validationError.state.message)}
                    helperText={validationError.state.message}
                    id="message"
                    multiline
                    rows={4}
                    label={<Typography variant="font15">Message</Typography>}
                    inputProps={{ maxLength: 250 }}
                    color="primary"
                    fullWidth
                    value={message.state}
                    onChange={(e) => {
                      validationError.update({
                        ...validationError.state,
                        message: "",
                      });
                      message.update(e.target.value);
                    }}
                    onBlur={(e) => {
                      if (isEmptyString(e.target.value)) {
                        validationError.update({
                          ...validationError.state,
                          message: "Message cant be empty",
                        });
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box p={4}>
              <Grid container justifyContent="flex-end">
                <Button
                  disabled={disableButton()}
                  variant="contained"
                  size="small"
                  onClick={(e) => {
                    checkValidatorOnAddThread() && callCreateThread();
                  }}
                >
                  <Typography
                    variant="font14b"
                    color={COLORS.white}
                    mx={4}
                    noWrap
                  >
                    Create thread
                  </Typography>
                  {isLoader.state && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        marginLeft: "-10px",
                      }}
                    />
                  )}
                </Button>
              </Grid>
            </Box>
          </Paper>
        </Box>
      </Modal>
      <Toast
        severity={severity.state}
        toastMessage={toastMessage.state}
        toastOpen={toastOpen}
      />
    </Fragment>
  );
}

export default CreateThreadModal;
