import React from "react";
import {
  Typography,
  Box,
  Grid,
  Stack,
  Paper,
  IconButton,
  Tooltip,
} from "@mui/material";
import { COLORS } from "constants";

import { scrollableSide } from "components/common/Styles";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import AddIcon from "@mui/icons-material/Add";
import { ThreeDots } from "react-loader-spinner";

function EmailTemplateLeftSection(props) {
  const {
    isHideLeft,
    isUpdateTemplate,
    selectedEmailTemplate,
    emailTemplates,
    resetvalidations,
  } = props;
  const classes = scrollableSide();

  const renderAddNewEmailTemplateButton = () => {
    return (
      <Tooltip
        title="Add new email template"
        placement={isHideLeft.state ? "right-start" : "bottom"}
      >
        <IconButton
          size="small"
          sx={{
            "&:hover": {
              backgroundColor: COLORS.primary,
              color: COLORS.white,
            },
          }}
          onClick={(e) => {
            isUpdateTemplate.update(false);
            selectedEmailTemplate.update(null);
            resetvalidations();
          }}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
    );
  };

  return (
    <Grid container item xs={isHideLeft.state ? 0.5 : 3}>
      <Paper variant="outlined" sx={{ width: "100%" }}>
        <Box
          p={!isHideLeft.state && 1}
          display={isHideLeft.state && "flex"}
          flexDirection="column"
          alignItems="center"
        >
          {isHideLeft.state ? (
            <Stack>
              <Tooltip
                title="Show email template types"
                placement="right-start"
              >
                <IconButton
                  size="small"
                  sx={{
                    "&:hover": {
                      backgroundColor: COLORS.primary,
                      color: COLORS.white,
                    },
                  }}
                  onClick={(e) => {
                    isHideLeft.update(false);
                  }}
                >
                  <KeyboardArrowRightOutlinedIcon />
                </IconButton>
              </Tooltip>
              {renderAddNewEmailTemplateButton()}
            </Stack>
          ) : (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="font15b" color={COLORS.primary}>
                Email template types
              </Typography>
              <Stack direction="row">
                {renderAddNewEmailTemplateButton()}
                <Tooltip title="Hide email template types">
                  <IconButton
                    size="small"
                    sx={{
                      "&:hover": {
                        backgroundColor: COLORS.primary,
                        color: COLORS.white,
                      },
                    }}
                    onClick={(e) => {
                      isHideLeft.update(true);
                    }}
                  >
                    <KeyboardArrowLeftOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          )}
          <Box p={1}>
            <Grid
              container
              spacing={2}
              my={2}
              justifyContent="center"
              className={classes.emailTemplateLeft}
            >
              {emailTemplates.state ? (
                emailTemplates.state.map((emailTemplate, index) => (
                  <Grid item xs={12} key={emailTemplate.emailTemplateId}>
                    <Tooltip
                      title={emailTemplate.emailTemplateType}
                      placement="right-start"
                    >
                      <Box
                        sx={{
                          p: !isHideLeft.state && 1,
                          borderRadius: 5,
                          border: "2px solid #ccc",
                          cursor: "pointer",
                          color: COLORS.primary,
                          display: "flex",
                          justifyContent: isHideLeft.state && "center",
                          "&:hover": {
                            backgroundColor: COLORS.primary,
                            color: COLORS.white,
                          },
                        }}
                        onClick={() => {
                          selectedEmailTemplate.update(emailTemplate);
                          isUpdateTemplate.update(true);
                          resetvalidations();
                        }}
                      >
                        {isHideLeft.state ? (
                          <Typography variant="font15">{index + 1}</Typography>
                        ) : (
                          <Typography variant="font15" noWrap>{`${index + 1}. ${
                            emailTemplate.emailTemplateType
                          }`}</Typography>
                        )}
                      </Box>
                    </Tooltip>
                  </Grid>
                ))
              ) : (
                <ThreeDots height="30" color={COLORS.primary} />
              )}
            </Grid>
          </Box>
        </Box>
      </Paper>
    </Grid>
  );
}

export default EmailTemplateLeftSection;
