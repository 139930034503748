import { decryptData } from "utils/EncryptDecryptLocal";
import { capitalize } from "./WordsUtils";
import { USER_TYPES } from "constants";

const getUserType = () => {
  try {
    return decryptData("user").userType;
  } catch {
    return null;
  }
};

export const getUserName = () => {
  const { firstName, lastName } = decryptData("user");
  return `${capitalize(firstName)} ${capitalize(lastName)}`;
};

export const getUserId = () => {
  return decryptData("user").userId;
};

const checkUserRole = (userType) => {
  const currentUserType = getUserType();
  return currentUserType === userType;
};

export const useIsSuperAdmin = () => checkUserRole(USER_TYPES.superAdmin);

export const useIsAdmin = () => checkUserRole(USER_TYPES.admin);

export const useIsAuthor = () => checkUserRole(USER_TYPES.author);

export const useIsAudioEngineer = () => checkUserRole(USER_TYPES.audioEngineer);

export const useIsAuthorOrEngineer = () =>
  checkUserRole(USER_TYPES.author) || checkUserRole(USER_TYPES.audioEngineer);
