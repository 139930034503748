import React, { Fragment, useEffect } from "react";
import HowToBuild from "./sections/HowToBuild";
import SetupRecording from "./sections/SetupRecording";
import RecordYourBook from "./sections/RecordYourBook";
import PublishBook from "./sections/PublishBook";
import { useStateUpdate } from "UseStateUpdate";
import { Box, IconButton, Stack } from "@mui/material";
import setupIcon from "assets/images/setupSide.png";
import recordIcon from "assets/images/recordSide.png";
import publishIcon from "assets/images/publishSide.png";
import homeIcon from "assets/images/homeSide.png";
import { homePage } from "components/common/Styles";
import { useLocation } from "react-router-dom";
import FooterSection from "components/homepage/sections/FooterSection";

function HowItWorks() {
  const classes = homePage();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const divParam = searchParams.get("div");
  const divToScroll = useStateUpdate(null);

  useEffect(() => {
    const element = document.getElementById(divParam);
    if (element) {
      element.scrollIntoView({
        block: "center",
      });
    }
  }, [divParam]);

  useEffect(() => {
    if (divToScroll.state) {
      const element = document.getElementById(divToScroll.state);
      if (element) {
        element.scrollIntoView({
          block: "center",
          behavior: "smooth",
        });
      }
    }
  }, [divToScroll.state]);

  return (
    <Fragment>
      <Box
        style={{
          position: "fixed",
          top: "30vh",
          left: "10px",
          padding: "10px",
          zIndex: "9999",
        }}
      >
        <Stack spacing={1}>
          <IconButton
            size="small"
            onClick={(e) => {
              divToScroll.update("home");
            }}
          >
            <img src={homeIcon} className={classes.sideMenu} alt="homeIcon" />
          </IconButton>
          <IconButton
            size="small"
            onClick={(e) => {
              divToScroll.update("setup");
            }}
          >
            <img src={setupIcon} className={classes.sideMenu} alt="setupIcon" />
          </IconButton>
          <IconButton
            size="small"
            onClick={(e) => {
              divToScroll.update("record");
            }}
          >
            <img
              src={recordIcon}
              className={classes.sideMenu}
              alt="recordIcon"
            />
          </IconButton>
          <IconButton
            size="small"
            onClick={(e) => {
              divToScroll.update("publish");
            }}
          >
            <img
              src={publishIcon}
              className={classes.sideMenu}
              alt="recordIcon"
            />
          </IconButton>
        </Stack>
      </Box>
      <div id="home"></div>
      <HowToBuild {...{ divToScroll }} />
      <div id="setup"></div>
      <SetupRecording />
      <div id="record"></div>
      <RecordYourBook />
      <div id="publish"> </div>
      <PublishBook />
      <FooterSection />
    </Fragment>
  );
}

export default HowItWorks;
