import React from "react";
import { IconButton, Tooltip } from "@mui/material";
import SaveParagraphIcon from "assets/images/save.png";
import {
  verifyRightSideButtons,
  iconButtonImage,
} from "components/common/Styles";

function AddParagraphButton(props) {
  const {
    content,
    callCreatePara,
    currentEditingParagraph,
    callUpdateParagraph,
    handleSaveCancel,
    SaveParagraph,
    setConfirmModalData,
  } = props;

  const classes = verifyRightSideButtons();
  const classes2 = iconButtonImage();

  const handleSaveChanges = (content) => {
    const onSave = () => {
      if (content.newPara) {
        callCreatePara({
          orderIndex: content.orderIndex,
          paragraphText: currentEditingParagraph.state,
        });
      } else {
        callUpdateParagraph(content.paragraphId, {
          paragraphText: currentEditingParagraph.state,
        });
      }
    };

    setConfirmModalData(
      SaveParagraphIcon,
      "Save paragraph",
      "Are you sure you want to save the changes?",
      "Yes, save paragraph",
      "Cancel, discard changes",
      onSave,
      handleSaveCancel
    );
  };

  return (
    <Tooltip title="Save changes" placement="top">
      <IconButton
        color="primary"
        className={classes.button}
        onClick={() => handleSaveChanges(content)}
      >
        <img src={SaveParagraph} className={classes2.buttonImage} alt="save" />
      </IconButton>
    </Tooltip>
  );
}

export default AddParagraphButton;
