export const COLORS = {
  primary: "#273891",
  secondary: "#04ADEF",
  primary50t: "rgba(39, 56, 145, 0.5)",
  white: "#fff",
  black: "#000",
  gray: "#ccc",
  dimGrey: "#686868",
  uploadBooksCreated: "#2ECC71",
  uploadedBooksStatus: "#FF5733",
  booksProcessingStatus: "#5957A3",
  verifyBooksStatus: "#A83279",
  groupChaptersStatus: "#17A589",
  recordingBooksStatus: "#FA7A9C",
  audioMergingStatus: "#FAAA5C",
  recordedBooksStatus: "#F1C40F",
  audioOptimizationInProgrssStatus: "#1ABC9C",
  audioOptimizationStatus: "#D35400",
  readyForProductionStatus: "#8E44AD",
  completedBooksStatus: "#3498DB",
  miscellaneous: "#E67E22",
  statusText: "#006585",
  aliceBlue: "#edf0f2",
  activeGreen: "#84D434",
  green: "#008000",
  inActiveRed: "#FF0000",
  gray61: "#9c9c9c",
  drawerBackground: "rgba(0, 0, 0, 0.75)",
  drawerHeaderBackground: "#00000080",
};
