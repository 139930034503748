import { Box, Grid, Link, Stack, Typography } from "@mui/material";
import logo from "assets/images/logo.png";
import { SUPPORT_MAIL as supportMail } from "constants/otherConstant";
import { COLORS } from "constants";
import { homePageStyle } from "components/common/Styles";

function FooterSection() {
  return (
    <Box mt={10}>
      <Box sx={homePageStyle.footerSection}>
        <Grid container>
          <Grid
            item
            xs={3}
            container
            justifyContent="center"
            alignItems="center"
          >
            <img src={logo} alt="logo" style={{ width: "20vh" }} />
          </Grid>{" "}
          <Grid
            item
            xs={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Stack display="flex" alignItems="center">
              <Typography variant="font12" color={COLORS.dimGrey}>
                Contact us
              </Typography>
              <Link
                href={`mailto: ${supportMail}`}
                variant="font13"
                color="secondary.light"
                underline="none"
              >
                {supportMail}
              </Link>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default FooterSection;
